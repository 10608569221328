import React from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import {
  Box,
  Checkbox,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Divider,
  Typography,
  ListSubheader,
  Autocomplete,
  TextField,
  TextFieldProps,
  Stack,
  Chip,
  InputLabel,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { SelectedItems } from '../../../doc-search/config';
import { filterByCategory } from './helper';

const CustomInput = styled(({ className, ...props }: TextFieldProps) => <TextField {...props} />)(
  ({ theme }) => {
    return {
      'label + &': {
        marginTop: theme.spacing(3.5),
      },
      '& .MuiAutocomplete-root': {
        maxHeight: 80,
        overflowY: 'auto',
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #ced4da',
        fontSize: 16,
        padding: '10px 26px 10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        '&:focus': {
          borderRadius: 4,
          borderColor: '#80bdff',
          boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
      },
    };
  }
);

function getStyles(name: string, value: string[], theme: Theme) {
  return {
    fontWeight:
      value.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

type Option = {
  value: string;
  label: string;
  category?: string;
};

interface MultipleSelectProps {
  options: Option[];
  selectedValue: string[];
  dispatchFilter: (id: SearchFilterId, items: SelectedItems) => void;
  name: SearchFilterId;
  selectAll?: boolean;
  label: string;
  compact?: boolean;
  grouped?: boolean;
}

const group = (options: Option[]) =>
  options.map((option: any) => {
    const last = option.label.split('>');
    const category = last[0].trim();
    return { ...option, category };
  });

export default function MultipleSelect({
  options,
  selectedValue,
  name,
  label,
  dispatchFilter,
  compact = true,
  grouped = false,
}: MultipleSelectProps) {
  const compactStyle = compact ? { py: 0, fontSize: 12, minHeight: 24, height: 24 } : {};
  const theme = useTheme();
  const SELECT_ALL = 'SELECT ALL';
  const filteredOptions = options.filter(({ value, label }) => (value || label) && value !== 'ALL');

  const handleGroupChange = (values: any) => {
    if (values.length === 0) {
      return dispatchFilter(name, []);
    }

    const found = filteredOptions.find((option) => {
      const value = values[values.length - 1];

      return (value?.label ?? value) === option.label;
    }) as any;
    const foundSelected = selectedValue.find((val: any) => val.value === found?.value);

    if (foundSelected) {
      dispatchFilter(
        name,
        [...selectedValue].filter(
          (val: any) => val.value !== found.value
        ) as unknown as SelectedItems
      );
    } else {
      dispatchFilter(name, [...selectedValue, found] as unknown as SelectedItems);
    }
  };

  const handleGroupSelectAllChange = (values: any, list: any, category: string) => {
    let valuesToDispatch = [];

    const filtered = filterByCategory(values, category);
    const cleanFiltered = filterByCategory(values, category, true);

    if (filtered.length === list.length) {
      valuesToDispatch = cleanFiltered;
    } else {
      const myList = filteredOptions.filter(({ label }: any) => label.split(' >')[0] === category);

      valuesToDispatch = [...cleanFiltered, ...myList];
    }

    dispatchFilter(name, valuesToDispatch as unknown as SelectedItems);
  };

  const isAllCategorySelected = (children: any, category?: string) => {
    const list = children.map((child: { key: string }) => child.key);

    if (list.length > 0 && category) {
      const filtered = filterByCategory(selectedValue, category);
      const filteredList = list.filter((val: any) => val.split(' >')[0] === category);
      return filtered.length === filteredList.length;
    }

    return false;
  };

  const displayGroupedOptions = (option: any) => (
    <Box key={`${name}-${option.group}`}>
      <ListSubheader sx={{ top: -8 }}>{option.group}</ListSubheader>
      <MenuItem
        value={`${SELECT_ALL} ${option.group}`}
        sx={compactStyle}
        onClick={() => handleGroupSelectAllChange(selectedValue, option.children, option.group)}
      >
        <ListItemIcon>
          <Checkbox
            sx={{ p: 0, m: 1 }}
            checked={isAllCategorySelected(option.children, option.group)}
            indeterminate={isAllCategorySelected(option.children, option.group)}
          />
        </ListItemIcon>
        <ListItemText disableTypography>
          <Typography sx={{ fontWeight: 700 }}>
            {SELECT_ALL} {option.group}
          </Typography>
        </ListItemText>
      </MenuItem>
      <Divider />
      <ul>
        {option.children.map((child: any, index: any) => {
          const val = child.key.replace(`${option.group} >`, '');

          return (
            <MenuItem
              component="li"
              role="option"
              tabIndex={-1}
              data-option-index={child.props['data-option-index']}
              onClick={() => handleGroupChange(selectedValue.concat(child.key))}
              key={`${name}-${option.group}-${val}-${index}`}
              value={val}
              style={getStyles(val, selectedValue, theme)}
              sx={compactStyle}
            >
              <Checkbox
                sx={{ p: 0, m: 1 }}
                checked={selectedValue.map((value: any) => value.label).indexOf(child.key) > -1}
              />
              <ListItemText primary={val} />
            </MenuItem>
          );
        })}
      </ul>
    </Box>
  );

  return (
    <Stack sx={{ width: 1 }}>
      <Autocomplete
        sx={{
          '& .MuiInputBase-root': {
            maxHeight: 160,
            overflow: 'auto',
            border: '1px solid rgba(0, 0, 0, 0.23)',
            boxShadow: 'none',
          },
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
          },
        }}
        id="autoCompleteMultipleSelect"
        multiple
        options={group(filteredOptions)}
        groupBy={(option) => option.category}
        value={selectedValue}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        onChange={(e, values) => dispatchFilter(name, [...values] as unknown as SelectedItems)}
        renderInput={(params) => (
          <>
            <InputLabel
              sx={{
                lineHeight: '1.375rem',
                fontWeight: 600,
                mb: '0.25rem',
                color: '#041c2c',
                transform: 'initial',
                fontSize: 16,
              }}
              shrink
              htmlFor="autoCompleteMultipleSelect"
            >
              {label}
            </InputLabel>
            <CustomInput {...params} />
          </>
        )}
        renderGroup={(params) => displayGroupedOptions(params)}
        renderTags={(value, getTagProps) => {
          return (
            <>
              {value.map((option, index) => (
                <Chip {...getTagProps({ index })} key={index} size="small" label={option.label} />
              ))}
            </>
          );
        }}
      />
    </Stack>
  );
}
