import React, { useState } from 'react';
import { Box, Stack, Tooltip } from '@mui/material';
import Button from '../../components/styledComponents/Button';
import { OldSelect } from '../../components/styledComponents/Select/Select';
import { useGetOrg } from '../../services/getOrg';
import { SearchResults, fetchSearchResults, saveOrg } from '../../api';
import { useQueryClient } from '@tanstack/react-query';
import DocumentResultCard from '../../doc-search/DocumentResultCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { notify } from '../../utils/helper';

const ArchiveOrgHierarchy = () => {
  const { data: orgData } = useGetOrg();
  const queryClient = useQueryClient();

  const [selectedOrg, setSelectedOrg] = useState<ExtendedOption | undefined>(undefined);
  const [docs, setDocs] = useState<SearchResults>();
  const [canArchive, setCanArchive] = useState<boolean>(false);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleArchive = async (isArchive: boolean) => {
    const archivedOrgGroupSplit = selectedOrg?.value.split('|');
    if (archivedOrgGroupSplit) {
      const response = await saveOrg([
        {
          type: 'GROUP',
          unit: archivedOrgGroupSplit[0],
          subunit: archivedOrgGroupSplit[1],
          product: archivedOrgGroupSplit[2],
          lineOfBusiness: archivedOrgGroupSplit[3],
          archived: isArchive,
        },
      ]);

      if (response) {
        notify(`Org Hierarchy successfully ${isArchive ? 'archived' : 'reactivated'}`, 'success');
      } else {
        notify(`Failed to ${isArchive ? 'archive' : 'reactivate'} Org Hierarchy`, 'error');
      }

      queryClient.invalidateQueries({ queryKey: ['org'] });
      setSelectedOrg(undefined);
    }
  };

  const handleArchiveSelect = async (org: ExtendedOption) => {
    setSelectedOrg(undefined);
    setIsLoading(true);

    const splitArray = org.value.split('|');
    const hierarchy = splitArray.slice(0, 3).join('|');
    const lob = splitArray[3];

    const data: SearchResults = await fetchSearchResults(
      '',
      { hierarchy: ['ARCHIVE', hierarchy], form_status: ['Active'], line_of_business: [lob] },
      0,
      undefined,
      10
    );
    setIsLoading(false);
    setDocs(data);

    if (data?.count === 0) setCanArchive(true);
    else setCanArchive(false);

    setSelectedOrg(org);
  };

  return (
    <Box className="wrapper container">
      <Box className="content-box">
        <Box sx={{ flexGrow: 1, position: 'relative' }}>
          <Stack gap={3}>
            <h2 className="page-title margin-bottom-0">Archive Org Hierarchy</h2>
            <Stack direction={'row'} alignItems={'center'} gap={1}>
              <OldSelect
                id="archive"
                label={'What part of the business'}
                value={selectedOrg?.value ?? ''}
                handleChange={(e) => {
                  // this is necessary because the select component accepts a string as the value, not an obejct
                  const selectedOption = orgData?.hierarchyOptionsWithLOB.find(
                    (option) => option.value === e.target.value
                  );

                  if (selectedOption) handleArchiveSelect(selectedOption);
                }}
                options={
                  orgData?.hierarchyOptionsWithLOB.sort(
                    (a, b) => Number(a.archived) - Number(b.archived)
                  ) ?? []
                }
                labelSize="small"
                selectSize="small"
                width="200px"
                height="300px"
              />
              <Tooltip
                title={
                  <p style={{ fontSize: '14px', marginBottom: '0px' }}>
                    Archived Org Hierarchies appear at the bottom of the list.
                  </p>
                }
              >
                <InfoOutlinedIcon></InfoOutlinedIcon>
              </Tooltip>
            </Stack>

            {isLoading && (
              <Stack direction={'row'} sx={{ justifyContent: 'center' }}>
                <FontAwesomeIcon icon={faSpinner} size="3x" />
              </Stack>
            )}

            {selectedOrg && canArchive && (
              <Stack alignItems={'center'}>
                <h3 style={{ marginBottom: '0px' }}>
                  {!selectedOrg?.archived
                    ? `There are 0 Active Forms`
                    : 'This Org Hierarchy is currently archived'}
                </h3>
                <Button
                  sx={{ width: '200px' }}
                  onClick={() => handleArchive(!selectedOrg?.archived)}
                >
                  {!selectedOrg?.archived ? 'Archive Org Hierarchy' : 'Reactivate Org Hierarchy'}
                </Button>
              </Stack>
            )}

            {selectedOrg && !canArchive && (
              <Stack className="listing-card-row-results">
                <Stack direction={'row'} alignItems={'center'} gap={1}>
                  <h3 style={{ marginBottom: '0px' }}>
                    Showing {docs?.hits.length} of {docs?.count} Active Forms
                  </h3>
                  <Tooltip
                    PopperProps={{
                      sx: {
                        '& .MuiTooltip-tooltip': {
                          maxWidth: '450px',
                        },
                      },
                    }}
                    placement="right-end"
                    title={
                      <p style={{ fontSize: '14px', marginBottom: '0px' }}>
                        Org Hierarchies with at least one Active Form cannot be archived. <br />
                        To interact with forms, use the search page.
                      </p>
                    }
                  >
                    <InfoOutlinedIcon></InfoOutlinedIcon>
                  </Tooltip>
                </Stack>
                {docs?.hits.map((doc: any, idx: number) => (
                  <DocumentResultCard
                    doc={doc}
                    key={`${idx}-${doc._id}`}
                    isEditor={false}
                    headerHeight={0}
                    disableCardAnchor={true}
                    isArchive={true}
                  />
                ))}
              </Stack>
            )}
          </Stack>
        </Box>
      </Box>
    </Box>
  );
};

export default ArchiveOrgHierarchy;
