import { useEffect, useState } from 'react';
import { useAppContext } from '../Store';
import { useLocation, useNavigate } from 'react-router-dom';
import { refreshAuth } from '../reducer';
import * as queryString from 'query-string';

function AuthTokenRefresh() {
  const { state, dispatch } = useAppContext();
  const location = useLocation();
  const navigate = useNavigate();
  const [destination, setDestination] = useState('/');

  useEffect(() => {
    dispatch(refreshAuth());
    if (location.search) {
      const query = queryString.parse(location.search);
      if (query['goto']) {
        setDestination(queryString.stringify(query, { encode: false }).replace('goto=', ''));
      }
    }
  }, [dispatch, location]);

  useEffect(() => {
    if (state.auth.didProcessRedirectToken) {
      navigate(destination);
    }
  }, [navigate, destination, state.auth.didProcessRedirectToken]);

  return null;
}

export default AuthTokenRefresh;
