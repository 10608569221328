import React, { createContext, useContext, useReducer } from 'react';

import { initialState, reducer } from './reducer';

interface AppContextInterface {
  state: AppState;
  dispatch: React.Dispatch<any>;
}

export const AppContext = createContext<AppContextInterface>({
  state: initialState,
  dispatch: () => {},
});

export const StoreProvider = ({ children }: React.PropsWithChildren<any>): React.ReactElement => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return <AppContext.Provider value={{ state, dispatch }}>{children}</AppContext.Provider>;
};

export const useAppContext = () => useContext(AppContext);
