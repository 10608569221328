// Config object to be passed to Msal on creation
export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_AZURE_SSO_CLIENT_ID || 'eda96cc6-876b-499c-bf83-19bc4c491f5d',
    authority:
      process.env.REACT_APP_AZURE_SSO_AUTHORITY ||
      'https://login.microsoftonline.com/2699c809-cb2a-497e-8640-a5d8c112e246',
    redirectUri: `${process.env.REACT_APP_BASE_URL}/auth`,
    postLogoutRedirectUri: `${process.env.REACT_APP_BASE_URL}/logout`,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false,
  },
};

export const oktaConfig = {
  clientId: process.env.REACT_APP_OKTA_CLIENT_ID || '0oadj4pyw5WppApYE1d7',
  issuer: process.env.REACT_APP_OKTA_ISSUER || 'https://argogroup.oktapreview.com',
  redirectUri: `${process.env.REACT_APP_BASE_URL}/implicit/callback`,
  postLogoutRedirectUri: `${process.env.REACT_APP_BASE_URL}/logout`,
  scopes: ['openid', 'profile', 'email', 'groups'],
  pkce: true,
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
  scopes: ['User.read'],
  // FIXME: get a working scope for out own API to get a verifiable accessToken
  // scopes: ['api://eda96cc6-876b-499c-bf83-19bc4c491f5d/api.read'],
};
