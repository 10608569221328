import { UserRoles } from './userRoles';

export const accessPolicy: AccessControl = {
  applicableStates: {
    _delete: [],
    read: [UserRoles.ADMIN, UserRoles.MANAGER],
    write: [],
  },
  fileUpload: {
    _delete: [],
    read: [UserRoles.ADMIN, UserRoles.MANAGER],
    write: [],
  },
  form: {
    _delete: [UserRoles.ADMIN],
    read: [UserRoles.ADMIN, UserRoles.MANAGER],
    write: [UserRoles.ADMIN, UserRoles.MANAGER],
  },
  formDownload: {
    _delete: [],
    read: [UserRoles.ADMIN, UserRoles.MANAGER, UserRoles.IMPLEMENTERS],
    write: [],
  },
  formNumberAutocomplete: {
    _delete: [],
    read: [UserRoles.ADMIN, UserRoles.MANAGER],
    write: [],
  },
  formPublish: {
    _delete: [],
    read: [UserRoles.ADMIN, UserRoles.MANAGER],
    write: [],
  },
  formSubmit: {
    _delete: [],
    read: [UserRoles.BUSINESSUSER],
    write: [],
  },
};
