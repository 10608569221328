export const carriers = new Map([
  ['AGCIC', 'AGCIC - Argonaut Great Central Insurance Company'],
  ['AIC', 'AIC - Argonaut Insurance Company'],
  ['ALRIC', 'ALRIC - Argonaut Limited Risk Insurance Company'],
  ['AMIC', 'AMIC - Argonaut Midwest Insurance Company'],
  ['ARIS', 'ARIS - ARIS Title Insurance Company'],
  ['ASIC', 'ASIC - Argonaut Southwest Insurance Company'],
  ['CIC', 'CIC - Colony Insurance Company'],
  ['CSIC', 'CSIC - Colony Specialty Insurance Company'],
  ['PIC', 'PIC - Peleus Insurance Company'],
  ['RCIC', 'RCIC - Rockwood Casualty Insurance Company'],
  ['SCIC', 'SCIC - Somerset Casualty Insurance Company'],
  ['SMIC', 'SMIC - Select Markets Insurance Company'],
]);

export const carrierOptions = Array.from(carriers, ([value, label]) => ({ value, label }));
