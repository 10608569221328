import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

import ExportFilters from './ExportFilters';
import MetadataTable from './MetadataTable';

import './MetadataExport.css';
import { useAppContext } from '../../Store';

function MetadataExport(props: any) {
  const { state } = useAppContext();
  const { isLoading, formSets } = state;
  const { filtersAreDirty, wasRequested } = formSets;
  const hitsCount =
    formSets.hitsCount === formSets.hits?.length ? formSets.hitsCount : formSets.hits?.length;

  return (
    <div className="wrapper container">
      <div className="content-box">
        <section className="export-section">
          <div>
            <h2 className="page-title margin-bottom-0">Form set exports</h2>
            <p>Download forms for a certain business, product, carrier, and/or state.</p>
            <ExportFilters />
            <div className="query-hits">
              {isLoading && <FontAwesomeIcon icon={faSpinner} />}
              {!isLoading && hitsCount > 0 && (
                <>
                  <strong>{hitsCount} hits</strong>
                  <MetadataTable data={formSets.hits} />
                </>
              )}
              {!filtersAreDirty && wasRequested && !isLoading && hitsCount === 0 && (
                <strong>0 documents found. Please modify the query.</strong>
              )}
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default MetadataExport;
