import * as msal from '@azure/msal-browser';
import { AuthenticationResult, AuthError } from '@azure/msal-browser';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import jwt_decode from 'jwt-decode';

import { loginRequest, msalConfig } from './AuthConfig';
import { oktaConfig } from './AuthConfig';
import { OktaAuth, AccessToken } from '@okta/okta-auth-js';
import { getLocalAuth } from '../api';

class AzureSSO {
  msalInstance: msal.PublicClientApplication;
  oktaAuth: OktaAuth;

  constructor() {
    this.msalInstance = new msal.PublicClientApplication(msalConfig);
    this.oktaAuth = new OktaAuth(oktaConfig);
  }

  async initialize() {
    let accounts: any[] = [];
    try {
      await this.msalInstance.initialize();
      accounts = this.msalInstance.getAllAccounts();
      if (accounts && accounts.length) {
        this.msalInstance.setActiveAccount(accounts[0]);
      }
    } catch (error: any) {
      console.log(error);
    }

    const localAuth = getLocalAuth();
    let authenticationResult: AuthenticationResult | null = null;
    try {
      authenticationResult = await this.msalInstance.handleRedirectPromise();
    } catch (error: any) {
      const errorData: string = `errorMessage: ${error.errorCode}
message: ${error.errorMessage}`;
      console.error(errorData);
    }
    if (localAuth.provider === 'Okta') {
      return;
    }
    if (authenticationResult) {
      let roles = [] as Array<string>;
      let token = null;
      try {
        token = jwt_decode<any>(authenticationResult.idToken);
        if (token && Array.isArray(token.roles)) {
          roles = token.roles;
        }
      } catch (error) {
        console.warn('Failed to parse Azure idToken', error);
      }
      const auth = {
        provider: 'Azure',
        isAuthenticating: false,
        isAuthenticated: true,
        accessToken: authenticationResult.idToken,
        user: {
          name: authenticationResult.account?.name,
          email: authenticationResult.account?.username,
        },
        isTokenInvalid: false,
        roles: roles || [],
        didProcessRedirectToken: false,
      };
      localStorage.setItem('paper-auth', JSON.stringify(auth));
    }
  }

  getAccount() {
    try {
      return this.msalInstance.getActiveAccount() as msal.AccountInfo;
    } catch (error) {
      console.error(error);
      return null as unknown as msal.AccountInfo;
    }
  }

  async getToken(authState: Auth) {
    if (authState.isTokenInvalid || authState.isAuthenticating) {
      throw new Error('Token is invalid or authentication is in progress.');
    }
    if (authState.provider === 'Okta') {
      const accessToken: AccessToken = (await this.oktaAuth.tokenManager.get(
        'accessToken'
      )) as AccessToken;
      return accessToken.accessToken;
    }
    if (authState.provider === 'Azure') {
      let accounts = [];
      try {
        await this.msalInstance.initialize();
        accounts = this.msalInstance.getAllAccounts();
        this.msalInstance.setActiveAccount(accounts[0]);
      } catch (error: any) {
        console.log(error);
      }
      const account = this.getAccount();
      if (account) {
        try {
          const tokenResponse = await this.msalInstance.acquireTokenSilent({
            ...loginRequest,
            account: this.getAccount(),
          });
          if (tokenResponse === null) {
            console.error('[Azure] acquireTokenSilent error: tokenResponse is null');
          }
          return tokenResponse.idToken;
        } catch (error: any) {
          console.error('[Azure] acquireTokenSilent error', error);
        }
      } else {
        throw new Error('[Azure] account not found');
      }
    }
    throw new Error('[Azure] provider was unspecified, skipped proceeding with token retrieval');
  }

  async login() {
    try {
      await this.msalInstance.loginRedirect(loginRequest);
    } catch (error) {
      console.error('[Azure] login error', error);
    }
  }

  async tryReLogin(authState: Auth) {
    const retryLogin = async () => {
      return new Promise((resolve, reject) => {
        return this.msalInstance
          .handleRedirectPromise()
          .then((authenticationResult: AuthenticationResult | null) => {
            if (authenticationResult) {
              authState.isAuthenticating = false;
              authState.accessToken = authenticationResult.idToken;
              let roles = [] as Array<string>;
              let token = null;
              try {
                token = jwt_decode<any>(authenticationResult.idToken);
                if (token && Array.isArray(token.roles)) {
                  roles = token.roles;
                }
              } catch (error) {
                console.warn('Failed to parse Azure idToken', error);
              }
              authState.roles = roles;
              resolve({
                authState,
                authenticationResult,
              });
            } else {
              try {
                this.msalInstance.loginRedirect(loginRequest);
              } catch (error) {
                reject('No response from Azure SSO');
              }
            }
          })
          .catch((error: AuthError) => {
            const errorData: string = `errorMessage: ${error.errorCode}
              message: ${error.errorMessage}`;
            reject(errorData);
          });
      });
    };

    return await retryLogin();
  }

  getRoles = (authenticationResult: AuthenticationResult) => {
    let roles = [] as Array<string>;
    try {
      const decodedToken = jwt_decode<any>(authenticationResult && authenticationResult.idToken);
      if (decodedToken && Array.isArray(decodedToken.roles)) {
        roles = decodedToken.roles;
      }
    } catch (error) {
      console.warn('Failed to parse Azure idToken', error);
    }
    return roles;
  };
}

const azureSSO = new AzureSSO();

export {
  azureSSO,
  loginRequest,
  msalConfig,
  useMsal,
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
};
