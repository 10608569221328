import React, { useState } from 'react';
import moment from 'moment';
import { Box, Flex } from 'rebass';
import Select from 'react-select';
import Button from '../../components/styledComponents/Button';

import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';

import '../../doc-search/DateRangePicker.css';

import { SelectedItems } from '../../doc-search/config';

import './ExportFilters.css';
import {
  clearExportFilters,
  setFormSetsDateFilter,
  setFormSetsSelectFilter,
  updateFormSets,
} from '../../reducer';
import { exportMetadata, fetchMetadataList } from '../../services';
import { CircularProgress, Stack, Box as MuiBox, InputLabel } from '@mui/material';
import AutoCompleteMultipleSelect from '../../components/styledComponents/MultipleSelect/AutoCompleteMultipleSelect';
import { useAppContext } from '../../Store';
import { useGetOrg } from '../../services/getOrg';
import { stateCodeArray } from '../../constants';

const ExportFilters = () => {
  const { dispatch, state } = useAppContext();
  const { formSets } = state;
  const { data: orgData } = useGetOrg();

  const [isLoading, setIsLoading] = useState(false);
  const { filters } = formSets;

  const dispatchSetFormSetsSelectFilter = (id: FormSetsFilterId, items: SelectedItems) => {
    dispatch(setFormSetsSelectFilter(id, items));
  };

  return (
    <div className="export-filters">
      <Flex flexWrap="wrap" mx={-2}>
        <Box px={4} py={2} width={3 / 4}>
          <AutoCompleteMultipleSelect
            options={orgData?.hierarchyOptions ?? []}
            selectedValue={filters.hierarchy}
            name="hierarchy"
            dispatchFilter={(id: any, items: SelectedItems) =>
              dispatchSetFormSetsSelectFilter('hierarchy', items)
            }
            selectAll
            label="What part of the business"
            grouped
          />
        </Box>
        <Box width={1 / 5}></Box>
        <Box px={4} py={2} width={1 / 4}>
          <InputLabel htmlFor="lob-options">Type of insurance (LOB)</InputLabel>
          <Select
            id="lob-options"
            isMulti
            options={orgData?.lobOptions ?? []}
            onChange={(items: any) => dispatchSetFormSetsSelectFilter('line_of_business', items)}
            value={filters.line_of_business}
          />
        </Box>
        <Box px={4} py={2} width={1 / 4}>
          <InputLabel htmlFor="carrier-options">Carrier</InputLabel>
          <Select
            id="carrier-options"
            isMulti
            options={
              orgData?.carrierOptions.map((c) => {
                return { label: c.value, value: c.label };
              }) ?? []
            }
            onChange={(items: any) => dispatchSetFormSetsSelectFilter('carrier', items)}
            value={filters.carrier}
          />
        </Box>
        <Box px={4} py={2} width={1 / 4}>
          <InputLabel htmlFor="effective-on">Effective on</InputLabel>
          <DayPickerInput
            value={filters.state_effective_date_max}
            placeholder="From"
            format="LL"
            formatDate={formatDate}
            parseDate={parseDate}
            dayPickerProps={{
              numberOfMonths: 1,
            }}
            onDayChange={(selected: Date) => {
              dispatch(setFormSetsDateFilter('state_effective_date_max', selected));
              dispatch(setFormSetsDateFilter('state_expired_date_min', selected));
            }}
            inputProps={{ readOnly: true }}
          />
        </Box>
        <Box width={1 / 5}></Box>
        <Box px={4} py={2} width={1 / 4}>
          <InputLabel htmlFor="approved-for-use">Approved for use in:</InputLabel>
          <Select
            id="approved-for-use"
            isMulti
            options={stateCodeArray.map((x) => ({ label: x.label, value: x.key }))}
            onChange={(items: any) => dispatchSetFormSetsSelectFilter('state_filed', items)}
            value={filters.state_filed}
          />
        </Box>
        <Box px={4} py={2} width={1 / 4}>
          <InputLabel htmlFor="form-source">Form source</InputLabel>
          <Select
            id="form-source"
            isMulti
            options={orgData?.formSourceOptions ?? []}
            onChange={(items: any) => dispatchSetFormSetsSelectFilter('form_source', items)}
            value={filters.form_source}
          />
        </Box>
        <Box px={4} py={2} width={1 / 4}>
          <InputLabel htmlFor="form-type">Form type</InputLabel>
          <Select
            id="form-type"
            isMulti
            options={orgData?.formTypeOptions ?? []}
            onChange={(items: any) => dispatchSetFormSetsSelectFilter('form_type', items)}
            value={filters.form_type}
          />
        </Box>
        <Box width={1 / 4}></Box>
        <Stack sx={{ pt: '33px', py: 2, px: 4, width: 0.5 }} spacing={2} direction="row">
          <Button
            disabled={
              isLoading ||
              !state.formSets.filters.hierarchy ||
              state.formSets.filters.hierarchy.length === 0
            }
            onClick={async () => {
              setIsLoading(true);
              const formSets = await fetchMetadataList(state.formSets);
              dispatch(updateFormSets(formSets));
              setIsLoading(false);
            }}
          >
            {isLoading ? (
              <MuiBox sx={{ display: 'flex', width: 64, justifyContent: 'center' }}>
                <CircularProgress size={20} thickness={4} />
              </MuiBox>
            ) : (
              'Run report'
            )}
          </Button>
          <Button
            disabled={
              isLoading ||
              !state.formSets.filters.hierarchy ||
              state.formSets.filters.hierarchy.length === 0
            }
            onClick={async () => {
              setIsLoading(true);
              const formSets = await fetchMetadataList(state.formSets);
              setIsLoading(false);
              dispatch(updateFormSets(formSets));
              if (formSets.hits.length > 0) {
                await exportMetadata(formSets);
              }
            }}
          >
            {isLoading ? (
              <Box sx={{ display: 'flex', width: 94, justifyContent: 'center' }}>
                <CircularProgress size={20} thickness={4} />
              </Box>
            ) : (
              'Download CSV'
            )}
          </Button>
        </Stack>
        <Box px={4} py={2} width={1 / 4} style={{ paddingTop: '33px' }}>
          <Button onClick={() => dispatch(clearExportFilters())} variant="outlined">
            Clear
          </Button>
        </Box>
      </Flex>
    </div>
  );
};

function formatDate(date: Date, format: string) {
  return moment(date).format(format);
}

function parseDate(str: string) {
  const parsed = moment(str);
  if (moment.isDate(parsed)) {
    return parsed;
  }
  return undefined;
}

export default ExportFilters;
