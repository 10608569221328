import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faLock } from '@fortawesome/free-solid-svg-icons';
import { azureSSO } from './AzureSSO';
import { useAppContext } from '../Store';
import { clearUser, setUser } from '../reducer';
import { oktaConfig } from './AuthConfig';

function AuthBlock() {
  const navigate = useNavigate();
  const { dispatch, state } = useAppContext();
  const { authState, oktaAuth } = useOktaAuth();

  useEffect(() => {
    async function initialize() {
      await azureSSO.initialize();
    }
    initialize();
  }, []);

  const logout = useCallback(async () => {
    if (state.auth.provider === 'Okta') {
      oktaAuth.signOut({
        postLogoutRedirectUri: oktaConfig.postLogoutRedirectUri,
      });
    } else {
      azureSSO.msalInstance.logoutRedirect();
    }
  }, [oktaAuth, state.auth.provider]);

  /* Perform clean login again to obtain a new token - to be called after our API returns a 401 */
  async function relogin() {
    state.auth.isTokenInvalid = false;
    if (state.auth.provider === 'Okta') {
      setTimeout(() => {
        oktaAuth
          .getUser()
          .then((info: any) => {
            setUserInfo(info);
            dispatch(
              setUser(
                info.name,
                authState?.accessToken?.accessToken,
                'Okta',
                info.groups,
                info.email,
                info.preferred_username
              )
            );
          })
          .catch(() => {
            console.log('Failed to refresh credentials');
            dispatch(clearUser());
            navigate('/auth');
          });
      }, 1500);
    } else if (state.auth.provider === 'Azure') {
      dispatch(clearUser());
      setTimeout(async () => {
        navigate('/');
        try {
          await azureSSO.login();
        } catch (error) {
          console.error('[Azure] retryLogin or login error', error);
        }
      }, 1500);
    } else {
      navigate('/');
    }
  }

  let [userInfo, setUserInfo] = useState(null);

  useEffect(() => {
    if (state.auth.isAuthenticated) {
      setUserInfo({
        name: state.auth.user.name,
        role: state.auth.user.accessRole,
      } as any);
      return;
    }
    if (!authState?.isAuthenticated) {
      // When user isn't authenticated, forget any user info
      setUserInfo(null);
    } else {
      state.auth.isAuthenticating = true;
      oktaAuth
        .getUser()
        .then((info: any) => {
          setUserInfo(info);
          dispatch(
            setUser(
              info.name,
              authState?.accessToken?.accessToken,
              'Okta',
              info.groups,
              info.email,
              info.preferred_username
            )
          );
        })
        .catch((err: Error) => {
          console.log(err.name, err.message);
          state.auth.isAuthenticating = false;
          if (err.name === 'OAuthError') {
            state.auth.provider = 'Okta';
            logout();
          }
        });
    }
  }, [
    authState,
    dispatch,
    logout,
    oktaAuth,
    state.auth,
    state.auth.isAuthenticated,
    state.auth.isAuthenticating,
    state.auth.user,
  ]); // Update if Okta authState changes

  if (authState?.isPending || state.auth.isAuthenticating) {
    return (
      <div className="user-grp">
        <div className="user-wrapper">
          <FontAwesomeIcon icon={faSpinner} />
        </div>
      </div>
    );
  } else if (
    !authState?.isAuthenticated &&
    !state.auth.isAuthenticated &&
    !state.auth.isTokenInvalid
  ) {
    return (
      <div className="user-grp">
        <div className="user-wrapper">
          <span>
            <FontAwesomeIcon icon={faLock} onClick={() => navigate('/auth')} />
          </span>
        </div>
      </div>
    );
  } else if (state.auth.isTokenInvalid) {
    if (state.auth.isAuthenticating) {
      return null;
    }
    relogin();
    return null;
  }

  const name = userInfo
    ? (userInfo as any).name
    : state.auth.isAuthenticated && state.auth.user.name // NOTE: useEffect isn't triggered when state.auth changes
    ? state.auth.user.name
    : '';
  return (
    <div className="user-grp">
      <div className="user-wrapper">
        <img src="/toolkit/images/profile.svg" alt="" className="profile-icon" />
        <div className="user">
          <span className="user-name">{name}</span>
          <img src="/toolkit/images/chevron-down-rounded.svg" className="dropdown-arrow" alt="" />
        </div>
      </div>

      <div className="dropdown-nav contracted">
        <Link to="/" onClick={logout} className="logout">
          Log out
        </Link>
      </div>
    </div>
  );
}

export default AuthBlock;
