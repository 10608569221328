import './index.css';

import App from './App';
import React from 'react';
import { createRoot } from 'react-dom/client';

import { StoreProvider } from './Store';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { ThemeProvider } from '@mui/material';
import theme from './theme';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

const container = document.getElementById('root');
const root = createRoot(container!);

const router = createBrowserRouter([{ path: '*', element: <App /> }]);

function isCustomEvent(event: Event): event is CustomEvent {
  return 'detail' in event;
}

window.addEventListener('OnApiAuthError', (event: Event) => {
  const destination = ['/auth-refresh'];
  if (isCustomEvent(event)) {
    const { detail } = event;
    if (detail.pathname) {
      destination.push(detail.pathname);
    }
    router.navigate(destination.join('?goto='));
  }
});

root.render(
  <StoreProvider>
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <RouterProvider router={router} />
      </LocalizationProvider>
    </ThemeProvider>
  </StoreProvider>
);
