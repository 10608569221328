import { Stack } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { UserRoles } from '../constants';
import { useAppContext } from '../Store';
import { userHasRole } from '../utils/helper';
import './DocumentList.css';
import DocumentResultCard from './DocumentResultCard';
import useOutsideAlerter from '../services/useOutsideAlerter';

type DocumentListProps = {
  docs: Array<any>;
};

const DocumentList = (props: DocumentListProps) => {
  const { state } = useAppContext();
  const [height, setHeight] = useState<number>(0);
  const headerRef = useRef<null | HTMLDivElement>(null);
  const wrapperRef = useRef<null | HTMLDivElement>(null);
  const { isOutsideClick } = useOutsideAlerter(wrapperRef);

  useEffect(() => {
    const handleResize = () => {
      setHeight(headerRef.current?.offsetHeight || 0);
    };
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  if (props.docs.length > 0) {
    const isEditor = userHasRole(
      [UserRoles.MANAGER, UserRoles.ADMIN],
      state.auth.roles as UserRoles[]
    );

    return (
      <div className="listing-card-row__set" ref={wrapperRef}>
        <div className="listing-card-row listing-card-header" ref={headerRef}>
          <div className="cell cell--percent-20 text-bold">
            Form Number
            <br />
            and Title
          </div>
          <div className="cell cell--percent-60 text-bold positioning">
            <div className="subcell">
              <div className="cell--percent-25">Business Unit</div>
              <div className="cell--percent-25">Sub-Business Unit</div>
              <div className="cell--percent-25">Program/Product</div>
              <div className="cell--percent-25">Line of Business</div>
            </div>
            <span className="block match">
              Matched <strong>text</strong>
            </span>
          </div>
          <div className="cell cell--percent-10 text-bold">Form Type</div>
          <div className="cell cell--percent-10 text-bold">
            <span className="block">Business Type</span> and Carriers
          </div>
        </div>
        <Stack className="listing-card-row-results">
          {height !== 0 &&
            props.docs.map((doc, idx) => (
              <DocumentResultCard
                doc={doc}
                key={`${idx}-${doc._id}`}
                isEditor={isEditor}
                headerHeight={height}
                disableCardAnchor={isOutsideClick}
              />
            ))}
        </Stack>
      </div>
    );
  }
  return null;
};

export default DocumentList;
