import React from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { useLocation } from 'react-router-dom';
import * as queryString from 'query-string';

import { useAppContext } from '../Store';
import { setUser } from '../reducer';
import { getMergedDocumentsFile } from '../api';

function DocApiDownload(props: any) {
  const { authState } = useOktaAuth();
  const { state, dispatch } = useAppContext();
  const location = useLocation();

  if (!state.auth.accessToken) {
    if (authState.isAuthenticated && authState.accessToken) {
      dispatch(setUser('', authState.accessToken, 'Okta'));
    } else {
      console.error('Unauthenticated request');
    }
  }

  const query = queryString.parse(location.search);
  dispatch(getMergedDocumentsFile(query.forms as string));

  return (
    <section className="main">
      <div className="doc-header">
        <h1>The download will begin soon...</h1>
      </div>
    </section>
  );
}

export default DocApiDownload;
