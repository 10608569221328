import React, { useEffect, useCallback, useRef } from 'react';
import * as PDFObject from 'pdfobject-jest';

import { useAppContext } from '../Store';
import { Box } from '@mui/material';

type WebViewerProps = {
  source: string;
  allowAnnotations?: boolean;
};

const options = {
  height: '100%',
};

// allowAnnotations doesn't seem to be used here.  Also this component was very weird
// looking so I converted it and tried to recreate as best as I could.  I still think
// we need to look at this a tad more later.
const WebViewer = ({ source, allowAnnotations = false }: WebViewerProps) => {
  const prevSource = useRef<any>(null);
  const { state } = useAppContext();

  const loadDocument = useCallback(async () => {
    if (!source) {
      return;
    }

    PDFObject.embed(source, '#web-viewer-root', options);
  }, [source]);

  useEffect(() => {
    loadDocument();
    window.scrollTo(0, 0);
  }, [loadDocument]);

  useEffect(() => {
    if (!state.viewDoc.fileUrl || !state.viewDoc.pdf || source !== prevSource.current) {
      loadDocument();
    }
  }, [loadDocument, source, state]);

  useEffect(() => {
    prevSource.current = source;
  }, [source]);

  return <Box sx={{ height: 1 }} id="web-viewer-root" />;
};

export default WebViewer;
