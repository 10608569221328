import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { Chip, Stack, Typography, Box } from '@mui/material';
import Grid from '@mui/material/Grid';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import Button from '../components/styledComponents/Button';
import WebViewer from '../doc-view/WebViewer';

import './DocViewPage.css';
import { useAppContext } from '../Store';
import { loadDocFile } from '../services';
import { SET_PREVIOUS_VIEWDOC_ID, setViewDoc } from '../reducer';
import { UserRoles, accessPolicy } from '../constants';
import { logDocumentAccess, userHasAccess } from '../utils/helper';
import { Link } from 'react-router-dom';
import TableSkeleton from './TableSkeleton';

function renderRelatedLink(item: any) {
  const { line_of_business, bu, business_unit, opu, operating_unit, program_or_product } =
    item._source;

  return (
    <a
      href={`/view/${item._id}`}
      target="_blank"
      rel="noopener noreferrer"
      style={{ whiteSpace: 'break-spaces' }}
    >
      {bu || business_unit || '-'} &gt; {opu || operating_unit || '-'} &gt;{' '}
      {program_or_product || '-'} &gt; {line_of_business || '-'}
    </a>
  );
}

const getDateString = (date: string) => (date ? moment.utc(date).format('MM/DD/YYYY') : '-');

const getValue = (value: any) =>
  value !== undefined && value !== null && value !== '' ? value : '-';

type DocViewPageParams = {
  id: string;
};

function DocViewPage() {
  const { id } = useParams<DocViewPageParams>();
  const { state, dispatch } = useAppContext();
  const { isLoading, viewDoc } = state;
  const { downloadUrl, related, metadata = {}, hasError } = viewDoc;

  const [docLoading, setDocLoading] = useState<boolean>(true);

  useEffect(() => {
    window.scrollTo(0, 0);

    const getDocFiles = async () => {
      setDocLoading(true);
      const response = await loadDocFile(id!, state);
      response && dispatch(setViewDoc(response as ViewDoc));
      response && dispatch({ type: SET_PREVIOUS_VIEWDOC_ID, payload: response.id });
      setDocLoading(false);
    };

    if (id) {
      getDocFiles();
    }
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [dispatch, id]);

  if (hasError) {
    return isLoading === false ? (
      <p className="doc-error">There's been an error getting this document's details</p>
    ) : null;
  }

  const {
    bu,
    explanation_of_impact,
    form_number,
    form_source,
    form_status,
    form_title,
    form_type,
    form_type_of_business,
    form_usage_category,
    form_usage_rule,
    impact_on_coverage,
    issuance_options,
    line_of_business,
    notes,
    opu,
    program_or_product,
    state_filings,
    signature_required,
    variable_data_instructions,
    variable_static,
  } = metadata as any;

  const isAuthorizedToEdit =
    state.auth.roles && userHasAccess(accessPolicy.form.read, state.auth.roles as UserRoles[]);

  const isAuthorizedToDownload =
    state.auth.roles &&
    (userHasAccess(accessPolicy.formDownload.read, state.auth.roles as UserRoles[]) ||
      state.auth.roles.includes('Task.OriginalDocumentDownload'));

  const previewComponent = state.viewDoc.hasError ? (
    <div className="doc-header">
      <h3>The requested file is missing or faulty</h3>
    </div>
  ) : (
    <WebViewer source={state.viewDoc.fileUrl} />
  );

  return (
    <Box className="container container" sx={{ m: 2 }}>
      <Box className="content-box" sx={{ pt: 1, minHeight: 'calc(100vh - 85px)' }}>
        {docLoading ? (
          <TableSkeleton />
        ) : (
          <Grid
            container
            spacing={2}
            sx={{ '@media print': { displayPrint: 'block' }, minHeight: 'calc(100vh - 125px)' }}
          >
            <Grid item mobile={12} tablet={6}>
              <div>
                <Typography sx={{ fontWeight: 700, fontSize: 18, mb: 2, textAlign: 'center' }}>
                  {form_title}
                </Typography>
                <div className="doc-metadata">
                  <div className="doc-info" style={{ marginBottom: '1rem' }}>
                    Form <strong>{form_number}</strong> is a {form_source} {line_of_business}{' '}
                    {form_type}
                  </div>
                  <Stack spacing={4} direction="row" sx={{ width: 1 }}>
                    <p>
                      <strong>Business unit </strong>
                      <br />
                      {getValue(bu)}
                    </p>
                    <p>
                      <strong>Sub business unit </strong>
                      <br />
                      {getValue(opu)}
                    </p>
                    <p>
                      <strong>Program or product </strong>
                      <br />
                      {getValue(program_or_product)}
                    </p>
                    <p>
                      <strong>Business Type </strong>
                      <br />
                      {getValue(form_type_of_business)}
                    </p>
                  </Stack>
                  <Stack direction="row" spacing={1} sx={{ mb: '1rem', mt: 2 }}>
                    <Stack sx={{ width: 0.5 }}>
                      <Typography>
                        <strong>Rules and Usages</strong>
                      </Typography>
                      <TableContainer component={Paper} sx={{ mb: 2 }}>
                        <Table sx={{ minWidth: 200 }} size="small">
                          <TableBody>
                            <TableRow>
                              <TableCell>Form Use Category</TableCell>
                              <TableCell align="right">{getValue(form_usage_category)}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Form Usage Rule</TableCell>
                              <TableCell align="right">{getValue(form_usage_rule)}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Impact On Coverage</TableCell>
                              <TableCell align="right">{getValue(impact_on_coverage)}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Explanation Of Impact</TableCell>
                              <TableCell align="right">{getValue(explanation_of_impact)}</TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Stack>
                    <Stack sx={{ width: 0.5 }}>
                      <Typography>
                        <strong>Implementation Notes</strong>
                      </Typography>
                      <TableContainer component={Paper} sx={{ mb: 2 }}>
                        <Table sx={{ minWidth: 200 }} size="small">
                          <TableBody>
                            <TableRow>
                              <TableCell>Variable Field Instructions</TableCell>
                              <TableCell align="right">
                                {getValue(variable_static)}
                                {getValue(variable_data_instructions) !== '-' &&
                                  `. ${getValue(variable_data_instructions).replace(/\.+$/g, '')}.`}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Issuance Options</TableCell>
                              <TableCell align="right">{getValue(issuance_options)}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell component="th" scope="row">
                                Signature Required
                              </TableCell>
                              <TableCell align="right">
                                {getValue(signature_required) === 'true' ? 'Yes' : 'No'}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Notes</TableCell>
                              <TableCell align="right">{getValue(notes)}</TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Stack>
                  </Stack>
                  <Stack spacing={1}>
                    <Stack>
                      {(state_filings || []).length !== 0 && (
                        <>
                          <Typography>
                            <strong>Jurisdictions and Effective Dates</strong>
                          </Typography>
                          <TableContainer sx={{ maxHeight: 250, mb: 2 }} component={Paper}>
                            <Table
                              sx={{ minWidth: 0.5 }}
                              stickyHeader
                              size="small"
                              aria-label="a dense table"
                            >
                              <TableHead>
                                <TableRow>
                                  <TableCell width={'10%'}>
                                    <strong>State</strong>
                                  </TableCell>
                                  <TableCell width={'25%'}>
                                    <strong>Carrier</strong>
                                  </TableCell>
                                  <TableCell>
                                    <strong>Status</strong>
                                  </TableCell>
                                  <TableCell>
                                    <strong>Effective</strong>
                                  </TableCell>
                                  <TableCell align="right">
                                    <strong>Expiry</strong>
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {(state_filings || []).length > 0 &&
                                  state_filings.map((state: any) => (
                                    <TableRow
                                      key={state.state_filed + state.carrier}
                                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                      <TableCell component="th" scope="row">
                                        {state.state_filed}
                                      </TableCell>
                                      <TableCell>{state.carrier}</TableCell>
                                      <TableCell>{state.state_status}</TableCell>
                                      <TableCell>{getDateString(state.effective_date)}</TableCell>
                                      <TableCell align="right">
                                        {getDateString(state.expiration_date)}
                                      </TableCell>
                                    </TableRow>
                                  ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </>
                      )}
                    </Stack>
                    <Stack>
                      {(related || []).length !== 0 && (
                        <>
                          <Typography>
                            <strong>Also Used In</strong>
                          </Typography>
                          <Typography fontSize={'14px'}>
                            Links are composed of{' '}
                            <i>
                              Business unit &gt; Sub business unit &gt; Program or Product &gt; Line
                              of Business information
                            </i>
                          </Typography>
                          <TableContainer component={Paper} sx={{ maxHeight: 250 }}>
                            <Table stickyHeader sx={{ minWidth: 0.5 }} size="small">
                              <TableBody>
                                {(related || []).length > 0 &&
                                  related.map((item: any) => (
                                    <TableRow
                                      key={item._id}
                                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                      <TableCell colSpan={4}>{renderRelatedLink(item)}</TableCell>
                                    </TableRow>
                                  ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </>
                      )}
                    </Stack>
                  </Stack>
                </div>
              </div>
            </Grid>
            <Grid item mobile={12} tablet={6}>
              <Stack height={1}>
                <Stack height={1}>
                  <Stack sx={{ mb: 2 }} spacing={2} direction="row">
                    {isAuthorizedToDownload && (
                      <>
                        {downloadUrl && (
                          <a
                            href={downloadUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={() => logDocumentAccess(form_number, 'download')}
                          >
                            <Button color="primary">Download original document</Button>
                          </a>
                        )}
                        {isAuthorizedToEdit && (
                          <Link to={`/forms/${id}`}>
                            <Button
                              sx={{
                                backgroundColor: 'primary.main',
                                '&:hover': {
                                  backgroundColor: 'primary.dark',
                                },
                              }}
                            >
                              Edit document
                            </Button>
                          </Link>
                        )}
                      </>
                    )}
                    {form_status !== undefined && (
                      <Chip
                        label={form_status}
                        sx={{
                          '&.MuiChip-root': { ml: 'auto' },
                          background: form_status === 'Active' ? 'green' : 'red',
                          color: 'common.white',
                          fontWeight: 700,
                        }}
                      />
                    )}
                  </Stack>
                  {previewComponent}
                </Stack>
              </Stack>
            </Grid>
          </Grid>
        )}
      </Box>
    </Box>
  );
}

export default DocViewPage;
