//TODO: PREVENT TYPING VALUE THAT ALREADY EXISTS NON ORG HIERARCHY
import React, { useState } from 'react';
import { Box, IconButton, Stack, TextField } from '@mui/material';
import Button from '../../components/styledComponents/Button';
import { OldSelect } from '../../components/styledComponents/Select/Select';
import { useGetOrg } from '../../services/getOrg';
import _ from 'lodash';
import DeleteIcon from '@mui/icons-material/Delete';
import { saveOrg } from '../../api';
import { useQueryClient } from '@tanstack/react-query';
import { strip } from '../../utils/helper';

enum DataSource {
  FORM_SOURCE = 'Form Source',
  FORM_TYPE = 'Form Type',
  CARRIER = 'Carrier',
}

enum Operation {
  ADD = 'ADD',
  DELETE = 'DELETE',
}

const Dictionary = () => {
  const { data: orgData } = useGetOrg();
  const queryClient = useQueryClient();
  const [dataSource, setDataSource] = useState<DataSource>(DataSource.FORM_SOURCE);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [operation, setOperation] = useState<Operation>(Operation.ADD);
  const [userKeyValues, setUserKeyValues] = useState<string[]>([]);
  const [carrier, setCarrier] = useState<string[]>(['', '']);
  const carrierKey = `${carrier[0]} - ${carrier[1]}`;
  const [key, setKey] = useState<string>('');
  const [isDuplicate, setIsDuplicate] = useState(false);
  const handleSave = async () => {
    let listToSave;

    listToSave = userKeyValues.map((key) => ({
      key: dataSource === DataSource.CARRIER ? key.split(' - ')[0] : key,
      value: key,
      type:
        dataSource === DataSource.CARRIER
          ? 'CARRIER'
          : dataSource === DataSource.FORM_SOURCE
          ? 'FORM_SOURCE'
          : 'FORM_TYPE',
    }));

    await saveOrg(listToSave);
    queryClient.invalidateQueries({ queryKey: ['org'] });

    setUserKeyValues([]);
  };

  return (
    <Box className="wrapper container">
      <Box className="content-box">
        <Box sx={{ flexGrow: 1, position: 'relative' }}>
          <Stack direction={'row'} alignItems={'center'}>
            <Box sx={{ width: '100%' }}>
              <h2 className="page-title margin-bottom-0">Add Dictionary</h2>
              {isDuplicate && <p>Duplicate item found.</p>}
            </Box>
            <OldSelect
              key={'data-source'}
              id={'data-source'}
              label={'Data Source'}
              value={dataSource || ''}
              handleChange={(e) => {
                setDataSource(e.target.value);
                setUserKeyValues([]);
                setKey('');
                //setIsDuplicate(false);
              }}
              options={[
                { value: DataSource.FORM_SOURCE, label: DataSource.FORM_SOURCE },
                { value: DataSource.FORM_TYPE, label: DataSource.FORM_TYPE },
                { value: DataSource.CARRIER, label: DataSource.CARRIER },
              ]}
              labelSize="small"
              selectSize="small"
            />
            {/* <OldSelect
              key={'operation'}
              id={'operation'}
              label={'Operation'}
              value={operation}
              handleChange={(e) => {
                setOperation(e.target.value);
              }}
              options={[
                { value: Operation.ADD, label: Operation.ADD },
                { value: Operation.DELETE, label: Operation.DELETE },
              ]}
              labelSize="small"
              selectSize="small"
            /> */}
          </Stack>

          {operation === Operation.ADD && (
            <>
              <Stack direction={'row'} alignItems={'center'}>
                {dataSource !== DataSource.CARRIER ? (
                  <TextField
                    placeholder={dataSource}
                    size="small"
                    sx={{ marginY: '1rem', width: '100%' }}
                    value={key}
                    onChange={(e) => {
                      setKey(e.target.value);
                      if (!e.target.value) {
                        setIsDuplicate(false);
                      }
                    }}
                  />
                ) : (
                  <>
                    <TextField
                      placeholder={'Carrier Acronym'}
                      size="small"
                      sx={{ marginY: '1rem', width: '100%' }}
                      value={carrier[0]}
                      onChange={(e) => setCarrier((prevState) => [e.target.value, prevState[1]])}
                    />
                    -
                    <TextField
                      placeholder={'Carrier Name'}
                      size="small"
                      sx={{ marginY: '1rem', width: '100%' }}
                      value={carrier[1]}
                      onChange={(e) => setCarrier((prevState) => [prevState[0], e.target.value])}
                    />
                  </>
                )}

                <Button
                  sx={{ maxHeight: '30px' }}
                  onClick={() => {
                    setIsDuplicate(false);
                    let currentVal = key;
                    if (!key && Array.isArray(carrier)) {
                      currentVal = carrier.join(' ')
                    }
                    if (!currentVal || (strip(currentVal).length !== currentVal.length)) return;
                    if (
                      (userKeyValues.some((k) => k === key) ||
                        orgData?.formSourceOptions.some(
                          (formSource) => formSource.value === key
                        )) &&
                      dataSource === DataSource.FORM_SOURCE
                    ){
                      setIsDuplicate(true);
                      return;
                    }
                    if (
                      (userKeyValues.some((k) => k === key) ||
                        orgData?.formTypeOptions.some((formType) => formType.value === key)) &&
                      dataSource === DataSource.FORM_TYPE
                    )
                    {
                      setIsDuplicate(true);
                      return;
                    };
                    if (
                      (userKeyValues.some((k) => k === carrierKey) ||
                        orgData?.carrierOptions.some((carrier) => carrier.label === carrierKey)) &&
                      dataSource === DataSource.CARRIER
                    )
                    {
                      setIsDuplicate(true);
                      return;
                    };

                    setUserKeyValues((prevState) =>
                      prevState.concat([dataSource !== DataSource.CARRIER ? key : carrierKey])
                    );
                    setKey('');
                    setCarrier(['', '']);
                  }}
                >
                  Add {dataSource}
                </Button>
              </Stack>
              <Stack direction={'row'} gap={4}>
                <Stack>
                  {dataSource === DataSource.FORM_TYPE &&
                    orgData?.formTypeOptions.map((opt) => <div key={opt.value}>{opt.value}</div>)}
                  {dataSource === DataSource.FORM_SOURCE &&
                    orgData?.formSourceOptions.map((opt) => <div key={opt.value}>{opt.value}</div>)}
                  {dataSource === DataSource.CARRIER &&
                    orgData?.carrierOptions.map((opt) => <div key={opt.label}>{opt.label}</div>)}
                </Stack>
                <Stack>
                  {userKeyValues.map((opt, index) => (
                    <div key={opt}>
                      {opt}
                      <IconButton
                        edge="end"
                        sx={{ marginLeft: 'auto' }}
                        aria-label="delete"
                        onClick={(e) => {
                          setUserKeyValues((prevState) => {
                            let newState = _.clone(prevState);
                            newState.splice(index, 1);
                            return newState;
                          });
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </div>
                  ))}
                </Stack>
              </Stack>
              <Button onClick={() => handleSave()} disabled={userKeyValues.length === 0}>
                Save
              </Button>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Dictionary;
