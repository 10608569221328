import Joi from 'joi';

export const defaultValidationSchema = {
  form_title: Joi.string().required(),
  form_number: Joi.string().required(),
  form_source: Joi.string().required(),
  form_type: Joi.string().required(),
  form_usage_category: Joi.string().allow(null, ''),
  impact_on_coverage: Joi.string().allow(null, ''),
  premium_bearing: Joi.string().default('No'),
  form_category: Joi.string().allow(null, ''),
  variable_static: Joi.string().allow(null, ''),
  manual_rate_condition: Joi.string().allow(null, ''),
};

export const businessUserValidationSchema = {
  form_title: Joi.string().required(),
  form_number: Joi.string().allow(null, ''),
  form_source: Joi.string().allow(null, ''),

  form_type: Joi.string().required(),
  issuance_options: Joi.alternatives().conditional('form_type', {
    switch: [
      { is: 'Certificate', then: Joi.string().required() },
      { is: 'Endorsement', then: Joi.string().required() },
      { is: 'M-Form', then: Joi.string().required() },
      { is: 'T-Form', then: Joi.string().required() },
      { is: 'Policyholder Notice', then: Joi.string().required() },
      { is: 'Selection/Rejection', then: Joi.string().required() },
      { is: 'State Amendatory', then: Joi.string().required() },
      { is: 'Other', then: Joi.string().required() },
    ],
    otherwise: Joi.string().allow(null, ''),
  }),

  form_usage_category: Joi.string().required(),
  form_usage_rule: Joi.alternatives().conditional('form_usage_category', {
    switch: [
      { is: 'Conditional', then: Joi.string().required() },
      { is: 'Optional', then: Joi.string().required() },
    ],
    otherwise: Joi.string().allow(null, ''),
  }),

  impact_on_coverage: Joi.string().required(),

  premium_bearing: Joi.string().required().default('No'),
  premium_info: Joi.alternatives().conditional('premium_bearing', {
    is: 'Yes',
    then: Joi.string().required(),
    otherwise: Joi.string().allow(null, ''),
  }),

  form_category: Joi.string().required(),
  named_insured: Joi.alternatives().conditional('form_category', {
    switch: [
      { is: 'M-Form', then: Joi.string().required() },
      { is: 'T-Form', then: Joi.string().required() },
    ],
    otherwise: Joi.string().allow(null, ''),
  }),
  policy_number: Joi.alternatives().conditional('form_category', {
    switch: [
      { is: 'M-Form', then: Joi.string().required() },
      { is: 'T-Form', then: Joi.string().required() },
    ],
    otherwise: Joi.string().allow(null, ''),
  }),
  state: Joi.alternatives().conditional('form_category', {
    switch: [
      { is: 'M-Form', then: Joi.string().required() },
      { is: 'T-Form', then: Joi.string().required() },
    ],
    otherwise: Joi.string().allow(null, ''),
  }),

  variable_static: Joi.string().allow(null, ''),
  variable_data_instructions: Joi.string().allow(null, ''),

  manual_rate_condition: Joi.string().required(),
  line_of_business_policy_details: Joi.string(),
  requested_effective_date: Joi.date().required(),
  explanation_of_impact: Joi.string().required(),
  notes: Joi.string().allow(null, ''),
};
