import authenticatedApi from './authenticatedApi';
import { LAMBDA_ORG } from './constants';
import { notify } from '../utils/helper';
import { useQuery } from '@tanstack/react-query';
import { handleUnauthorizedError } from '../api';
//import useForceLogout from './useForceLogout';

const shapeOrgData = (org: any) => {
  const obj: any = {};

  const unitOptions: Option[] = [];
  const lobOptions: Option[] = [];
  const subunitOptions: Option[] = [];
  const productOptions: Option[] = [];
  const hierarchyOptions: Option[] = [];
  const hierarchyOptionsWithLOB: ExtendedOption[] = [];

  const formSourceOptions: Option[] = [];
  const formTypeOptions: Option[] = [];
  const carrierOptions: Option[] = [];

  org.forEach((item: any) => {
    if (item.pk === 'ORG#GROUP') {
      const bu = item.unit;
      const opu = item.subunit;
      const pop = item.product;
      const lob = item.lineOfBusiness;

      unitOptions.push({
        label: item.unit,
        value: item.unit,
      });

      subunitOptions.push({
        label: item.subunit,
        value: item.subunit,
      });

      productOptions.push({
        label: item.product,
        value: item.product,
      });

      lobOptions.push({
        label: item.lineOfBusiness,
        value: item.lineOfBusiness,
      });
      hierarchyOptions.push({
        label: `${bu} > ${opu} > ${pop}`,
        value: `${bu}|${opu}|${pop}`,
      });

      hierarchyOptionsWithLOB.push({
        label: `${bu} > ${opu} > ${pop} > ${lob}`,
        value: `${bu}|${opu}|${pop}|${lob}`,
        archived: item?.archived ? true : false,
      });

      //filter out archived entries from shapedObject
      if (item?.archived) {
        return;
      }

      if (!obj[bu]) {
        obj[bu] = {};

        obj[bu][opu] = {};

        obj[bu][opu][pop] = [lob];
      } else if (!obj[bu][opu]) {
        obj[bu][opu] = {};

        obj[bu][opu][pop] = [lob];
      } else if (!obj[bu][opu][pop]) {
        obj[bu][opu][pop] = [lob];
      } else obj[bu][opu][pop].push(lob);
    } else if (item.pk === 'ORG#FORM_SOURCE') {
      formSourceOptions.push({
        label: item.key,
        value: item.value,
      });
    } else if (item.pk === 'ORG#FORM_TYPE') {
      formTypeOptions.push({
        label: item.key,
        value: item.value,
      });
    } else if (item.pk === 'ORG#CARRIER') {
      carrierOptions.push({
        label: item.value,
        value: item.key,
      });
    }
  });

  const filteredHierarchyOptions = hierarchyOptions.filter(
    (option: Option, index: number) =>
      index === hierarchyOptions.findIndex((o: Option) => o.label === option.label)
  );

  const filteredUnitOptions = unitOptions
    .filter(
      (option: Option, index: number) =>
        index === unitOptions.findIndex((o: Option) => o.label === option.label)
    )
    .sort((a, b) => (a.value.toUpperCase() < b.value.toUpperCase() ? -1 : 1));

  const filteredSubunitOptions = subunitOptions
    .filter(
      (option: Option, index: number) =>
        index === subunitOptions.findIndex((o: Option) => o.label === option.label)
    )
    .sort((a, b) => (a.value.toUpperCase() < b.value.toUpperCase() ? -1 : 1));

  const filteredProductOptions = productOptions
    .filter(
      (option: Option, index: number) =>
        index === productOptions.findIndex((o: Option) => o.label === option.label)
    )
    .sort((a, b) => (a.value.toUpperCase() < b.value.toUpperCase() ? -1 : 1));

  const filteredLobOptions = lobOptions
    .filter(
      (option: Option, index: number) =>
        index === lobOptions.findIndex((o: Option) => o.label === option.label)
    )
    .sort((a, b) => (a.value.toUpperCase() < b.value.toUpperCase() ? -1 : 1));

  const finalOrgObject: Org = {
    shapedHierarchyOptions: obj,
    hierarchyOptions: filteredHierarchyOptions,
    hierarchyOptionsWithLOB,
    lobOptions: filteredLobOptions,
    unitOptions: filteredUnitOptions,
    subunitOptions: filteredSubunitOptions,
    productOptions: filteredProductOptions,
    carrierOptions,
    formSourceOptions,
    formTypeOptions,
  };

  return finalOrgObject;
};

export const getOrg = async () => {
  try {
    const response = await authenticatedApi.get<any>(`${LAMBDA_ORG}/org`, {}, false);

    if (response.redirected) {
      throw new Error('Force Logout');
    }

    const org = await response.json();

    return shapeOrgData(org.items);
  } catch (error: any) {
    console.error(error);
    if (error?.message === 'Force Logout')
      notify('The application has been upgraded. Current session will be terminated.', 'warn');
    else if (!handleUnauthorizedError(error))
      notify('Failed to fetch the business hierarchy. Please try again later.', 'error');
    throw error;
  }
};

export const useGetOrg = () => {
  //const handleForceLogout = useForceLogout();
  return useQuery({
    queryKey: ['org'],
    queryFn: getOrg,
    staleTime: 1000 * 60 * 10,
    retry: 2,
    //onError: handleForceLogout,
  });
};