import ky from 'ky';
import { getLocalAuth } from '../api';
import { azureSSO } from '../auth/AzureSSO';
import { UserRoles } from '../constants';

async function getApiInstance() {
  const auth = getLocalAuth();

  if (auth.isTokenInvalid || auth.isAuthenticating) {
    throw new Error('Access token is missing or invalid, waiting for reauthentication...');
  }

  let userRoles: string[] = [];
  for (let [role, group] of Object.entries(UserRoles)) {
    if (auth.roles.includes(group)) {
      userRoles.push(role.toLowerCase());
    }
  }

  try {
    const token = await azureSSO.getToken(auth);
    if (!token) {
      throw new Error('Failed to get an access token');
    }
    return ky.extend({
      hooks: {
        beforeRequest: [
          (request) => {
            request.headers.set('Authorization', token);
            request.headers.set('AuthN', window.btoa(userRoles.join(',')));
          },
        ],
      },
      throwHttpErrors: true,
    });
  } catch (error) {
    return ky.extend({
      throwHttpErrors: true,
    });
  }
}

const authenticatedApi = {
  get: async <T>(url: string, options?: any, parseJSON = true): Promise<T | Response> => {
    try {
      const apiWithToken = await getApiInstance();
      const request = apiWithToken.get(url, options);
      if (parseJSON) {
        return request.json<T>();
      }
      return request;
    } catch (error) {
      console.error(`[API] Error at GET ${url}`, error);
      throw error;
    }
  },
  post: async <T>(url: string, options?: any, isBodyOnly = true): Promise<T> => {
    try {
      const apiWithToken = await getApiInstance();
      if (isBodyOnly) return await apiWithToken.post(url, options).json<T>();
      else return (await apiWithToken.post(url, options)) as T;
    } catch (error) {
      console.error(`[API] Error at POST ${url}`, error);
      throw error;
    }
  },
  put: async <T>(url: string, options?: any): Promise<T> => {
    try {
      const apiWithToken = await getApiInstance();
      return await apiWithToken.put(url, options).json<T>();
    } catch (error) {
      console.error(`[API] Error at PUT ${url}`, error);
      throw error;
    }
  },

  delete: async <T>(url: string, options?: any): Promise<T> => {
    try {
      const apiWithToken = await getApiInstance();
      return await apiWithToken.delete(url, options).json<T>();
    } catch (error) {
      console.error(`[API] Error at DELETE ${url}`, error);
      throw error;
    }
  },
};

export default authenticatedApi;
