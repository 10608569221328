import React from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { useLocation } from 'react-router-dom';
import * as queryString from 'query-string';
import { Button } from '@mui/material';
import './DocApiZip.css';
import { useAppContext } from '../Store';
import { getDocumentsMeta, getMergedDocumentsZip, setUser } from '../reducer';

function DocApiZip(props: any) {
  const { authState } = useOktaAuth();
  const location = useLocation();
  const { state, dispatch } = useAppContext();

  if (!state.auth.accessToken) {
    if (authState.isAuthenticated && authState.accessToken) {
      dispatch(setUser('', authState.accessToken, 'Okta'));
    } else {
      console.error('Unauthenticated request');
    }
  }

  const query = queryString.parse(location.search);
  if (state.download.status === 'init') {
    dispatch(getDocumentsMeta(query.forms as string, dispatch));
  }
  let status = 'The download will begin soon...';
  if (state.download.status === 'success') {
    status = 'Successfully downloaded.';
  }
  if (state.download.status === 'error') {
    status = 'Failed to download the requested documents.';
  }
  const alreadyRequested = ['pending', 'success', 'error'].includes(state.download.status);
  return (
    <section className="main">
      <div className="doc-header">
        <div className="columns">
          <div className="forms-list">
            <h1>Rater form selections</h1>
            {state.download.meta.map((item: any) => (
              <ReactiveFormsListItem item={item} />
            ))}
          </div>
          <div className="sidebar">
            {!alreadyRequested && (
              <Button
                variant="contained"
                onClick={() => dispatch(getMergedDocumentsZip(query.forms as string, dispatch))}
              >
                Download the listed documents
              </Button>
            )}
            {alreadyRequested && <h3>{status}</h3>}
          </div>
        </div>
      </div>
    </section>
  );
}

function FormsListItem(props: any) {
  const { item } = props;

  return (
    <div className={`columns forms-list-item ${item.is_missing ? 'missing' : ''}`}>
      <div className="form-number">{item.form_number}</div>
      <div className="form-title">{item.form_title}</div>
    </div>
  );
}
const ReactiveFormsListItem = FormsListItem; // this doesn't seem like it is used... weird

export default DocApiZip;
