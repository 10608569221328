import * as React from 'react';
import { TextField as MuiTextField } from '@mui/material';
import { Controller } from 'react-hook-form';
import { customDisable } from '../../../theme';

interface TextFieldProps {
  control: any;
  disabled?: boolean;
  errors?: any;
  id: string;
  label: string;
  required?: boolean;
  sx?: any;
}

const TextField = ({
  label,
  control,
  id,
  required = false,
  errors,
  disabled = false,
  sx,
}: TextFieldProps) => {
  let helperText = '';

  if (errors?.[id]?.type === 'required') {
    helperText = `${label} is required`;
  }

  return (
    <Controller
      name={id}
      control={control}
      rules={{ required }}
      render={({ field }) => {
        Object.assign(field, { value: field.value || '' });
        return (
          <MuiTextField
            disabled={disabled}
            error={!!errors?.[id]}
            id={id}
            fullWidth
            required={required}
            label={label}
            aria-invalid={errors?.[id] ? 'true' : 'false'}
            variant="outlined"
            helperText={helperText}
            sx={{ ...customDisable, ...sx }}
            {...field}
          />
        );
      }}
    />
  );
};

export default TextField;
