import { Stack } from '@mui/material';
import React from 'react';

const NotFound = () => {
  return (
    <Stack
      style={{
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        alignItems: 'center',
      }}
    >
      <h1>404 - Page Not Found</h1>
      <p>Sorry, the page you are looking for could not be found.</p>
    </Stack>
  );
};

export default NotFound;
