import React from 'react';
import {
  Accordion,
  AccordionSummary,
  Box,
  Typography,
  AccordionDetails,
  Tooltip,
  Grid,
  MenuItem,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ReactHookFormSelect } from '../../styledComponents/Select';
import { issuanceOptions, lineOfBusinessPolicyDetailsOptions } from '../constants';
import TextField from '../../styledComponents/TextField';
import DatePicker from '../../styledComponents/DatePicker';
import { InfoOutlined } from '@mui/icons-material';
import { stateCodeArray } from '../../../constants';
import { DependentFields } from '../DocEditContent';

interface ImplementationDetailsProps {
  control: any;
  errors: any;
  disabled: boolean;
  isFieldDisplayed: (id: DependentFields) => boolean;
  defaultExpanded: boolean;
  onChange: () => void;
  requiredKeys: string[];
}
function ImplementationDetails({
  control,
  errors,
  disabled,
  isFieldDisplayed,
  defaultExpanded,
  onChange,
  requiredKeys,
}: ImplementationDetailsProps) {
  return (
    <>
      <Accordion defaultExpanded={defaultExpanded}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="formDetailsAccordion"
        >
          <Typography variant="h6">Implementation Details</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={{ mobile: 2 }} className="grid">
            <Grid item mobile={12} tablet={6} sx={{ display: 'flex' }}>
              <ReactHookFormSelect
                control={control}
                label="Line of Business Policy Details"
                id="line_of_business_policy_details"
                errors={errors}
                disabled={disabled}
                defaultValue={''}
                name="line_of_business_policy_details"
                onChange={onChange}
              >
                {lineOfBusinessPolicyDetailsOptions.map((option: any) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </ReactHookFormSelect>
              <Box sx={{ m: 'auto', pl: 1 }}>
                <Tooltip title="Only applicable to certain lines of business">
                  <InfoOutlined sx={{ verticalAlign: 'middle' }} />
                </Tooltip>
              </Box>
            </Grid>
            <Grid item mobile={12} tablet={6}>
              <DatePicker
                sx={{ width: '50%' }}
                label={`${
                  requiredKeys.includes('requested_effective_date') ? '*' : ''
                } Requested Effective Date`}
                id="requested_effective_date"
                control={control}
                errors={errors}
                disabled={disabled}
                required
                onChange={onChange}
              />
            </Grid>
            <Grid item mobile={12} sx={{ display: 'flex' }}>
              <TextField
                id="explanation_of_impact"
                label={`${
                  requiredKeys.includes('explanation_of_impact') ? '*' : ''
                } Explanation of Impact`}
                control={control}
                errors={errors}
                disabled={disabled}
              />
              <Box sx={{ m: 'auto', pl: 1 }}>
                <Tooltip title="What will the attachment of this form accomplish? How will the attachment of this form change the policy?">
                  <InfoOutlined sx={{ verticalAlign: 'middle' }} />
                </Tooltip>
              </Box>
            </Grid>
            <Grid item mobile={12}>
              <TextField
                id="notes"
                label={`${requiredKeys.includes('notes') ? '*' : ''} Note`}
                control={control}
                errors={errors}
                disabled={disabled}
              />
            </Grid>
            {isFieldDisplayed(DependentFields.premium_info) && (
              <Grid item mobile={12}>
                <Box>
                  <Typography sx={{ fontSize: 14, fontWeight: 700, pb: 2 }}>
                    Since Premium bearing is selected
                  </Typography>
                  <Box sx={{ display: 'inline-flex', width: '100%' }}>
                    <TextField
                      id="premium_info"
                      label={`${requiredKeys.includes('premium_info') ? '*' : ''} Premium Info`}
                      control={control}
                      errors={errors}
                      disabled={disabled}
                    />
                    <Box sx={{ m: 'auto', pl: 1 }}>
                      <Tooltip title="Explain whether there is a flat charge, and whether there is a recommended premium or if it is a rated coverage.">
                        <InfoOutlined sx={{ verticalAlign: 'middle' }} />
                      </Tooltip>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            )}
            {isFieldDisplayed(DependentFields.named_insured) && (
              <Grid item mobile={12}>
                <Box>
                  <Typography sx={{ fontSize: 14, fontWeight: 700, pb: 2 }}>
                    Due to selected form category
                  </Typography>
                  <Box sx={{ display: 'inline-flex', width: '49.3%' }}>
                    <TextField
                      id="named_insured"
                      label={`${requiredKeys.includes('named_insured') ? '*' : ''} Named Insured`}
                      control={control}
                      errors={errors}
                      disabled={disabled}
                    />
                  </Box>
                </Box>
              </Grid>
            )}
            {isFieldDisplayed(DependentFields.policy_number) && (
              <Grid item mobile={12} tablet={6}>
                <TextField
                  id="policy_number"
                  label={`${requiredKeys.includes('policy_number') ? '*' : ''} Policy Number`}
                  control={control}
                  errors={errors}
                  disabled={disabled}
                />
              </Grid>
            )}
            {isFieldDisplayed(DependentFields.state) && (
              <Grid item mobile={12} tablet={6}>
                <ReactHookFormSelect
                  control={control}
                  label={`${requiredKeys.includes('state') ? '*' : ''} State`}
                  id="state"
                  errors={errors}
                  disabled={disabled}
                  defaultValue={''}
                  name="state"
                  onChange={onChange}
                >
                  {stateCodeArray.map((option: any) => (
                    <MenuItem key={option.key} value={option.key}>
                      {option.label}
                    </MenuItem>
                  ))}
                </ReactHookFormSelect>
              </Grid>
            )}
            {isFieldDisplayed(DependentFields.issuance_options) && (
              <Grid item mobile={12}>
                <Box sx={{ width: '49.3%' }}>
                  <Typography sx={{ fontSize: 14, fontWeight: 700, pb: 2 }}>
                    Due to selected form type
                  </Typography>
                  <ReactHookFormSelect
                    control={control}
                    label={`${requiredKeys.includes('issuance_options') ? '*' : ''} Issuance Options`}
                    id="issuance_options"
                    errors={errors}
                    disabled={disabled}
                    defaultValue={''}
                    name="issuance_options"
                    onChange={onChange}
                  >
                    {issuanceOptions.map((option: any) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </ReactHookFormSelect>
                </Box>
              </Grid>
            )}
            {isFieldDisplayed(DependentFields.variable_data_instructions) && (
              <Grid item mobile={12}>
                <Box>
                  <Typography sx={{ fontSize: 14, fontWeight: 700, pb: 2 }}>
                    Due to selected variable static
                  </Typography>
                  <TextField
                    id="variable_data_instructions"
                    label={`${requiredKeys.includes('variable_data_instructions') ? '*' : ''} Variable Data Instructions`}
                    control={control}
                    errors={errors}
                    disabled={disabled}
                  />
                </Box>
              </Grid>
            )}
            {isFieldDisplayed(DependentFields.form_usage_rule) && (
              <Grid item mobile={12}>
                <Box>
                  <Typography sx={{ fontSize: 14, fontWeight: 700, pb: 2 }}>
                    Due to selected form usage category
                  </Typography>
                  <Box sx={{ display: 'inline-flex', width: '50%' }}>
                    <TextField
                      id="form_usage_rule"
                      label={`${requiredKeys.includes('form_usage_rule') ? '*' : ''} Form Usage Rule`}
                      control={control}
                      errors={errors}
                      disabled={disabled}
                    />
                    <Box sx={{ m: 'auto', pl: 1 }}>
                      <Tooltip title="When will this form be attached?">
                        <InfoOutlined sx={{ verticalAlign: 'middle' }} />
                      </Tooltip>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            )}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </>
  );
}

export default ImplementationDetails;
