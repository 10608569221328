import React from 'react';
import { Stack, Typography, Box, Skeleton } from '@mui/material';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const TableSkeleton = () => (
  <Stack spacing={2} direction="row" sx={{ pt: 2, minHeight: 'calc(100vh - 125px)' }}>
    <Stack spacing={1} sx={{ flex: 1 }}>
      <Box>
        <Stack sx={{ mb: 2, width: 1 }} alignItems="center">
          <Skeleton variant="text" width="60%" />
        </Stack>
        <Box className="doc-metadata">
          <Box className="doc-info" style={{ marginBottom: '1rem' }}>
            <Skeleton variant="text" />
          </Box>
          <Stack spacing={4} direction="row" sx={{ width: 1 }}>
            <Typography sx={{ flex: 1 }}>
              <Skeleton variant="text" />
              <Skeleton variant="text" />
              <Skeleton variant="text" />
            </Typography>
            <Typography sx={{ flex: 1 }}>
              <Skeleton variant="text" />
              <Skeleton variant="text" />
              <Skeleton variant="text" />
            </Typography>
            <Typography sx={{ flex: 1 }}>
              <Skeleton variant="text" />
              <Skeleton variant="text" />
              <Skeleton variant="text" />
            </Typography>
            <Typography sx={{ flex: 1 }}>
              <Skeleton variant="text" />
              <Skeleton variant="text" />
              <Skeleton variant="text" />
            </Typography>
          </Stack>
          <Stack direction="row" spacing={1} sx={{ mb: 6, mt: 3 }}>
            <Stack sx={{ width: 0.5 }}>
              <Typography>
                <Skeleton variant="text" width="50%" />
              </Typography>
              <TableContainer component={Paper} sx={{ mb: 2 }}>
                <Table sx={{ minWidth: 200 }} size="small">
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <Skeleton variant="text" />
                      </TableCell>
                      <TableCell align="right">
                        <Skeleton variant="text" />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Skeleton variant="text" />
                      </TableCell>
                      <TableCell align="right">
                        <Skeleton variant="text" />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Skeleton variant="text" />
                      </TableCell>
                      <TableCell align="right">
                        <Skeleton variant="text" />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Skeleton variant="text" />
                      </TableCell>
                      <TableCell align="right">
                        <Skeleton variant="text" />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Stack>
            <Stack sx={{ width: 0.5 }}>
              <Typography>
                <Skeleton variant="text" width="60%" />
              </Typography>
              <TableContainer component={Paper} sx={{ mb: 2 }}>
                <Table sx={{ minWidth: 200 }} size="small">
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <Skeleton variant="text" />
                      </TableCell>
                      <TableCell align="right">
                        <Skeleton variant="text" />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Skeleton variant="text" />
                      </TableCell>
                      <TableCell align="right">
                        <Skeleton variant="text" />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Skeleton variant="text" />
                      </TableCell>
                      <TableCell align="right">
                        <Skeleton variant="text" />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Skeleton variant="text" />
                      </TableCell>
                      <TableCell align="right">
                        <Skeleton variant="text" />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Stack>
          </Stack>
          <Stack direction="row" spacing={1}>
            <Stack sx={{ width: 0.5 }}>
              <Typography>
                <Skeleton variant="text" />
              </Typography>
              <TableContainer sx={{ maxHeight: 250, mb: 2 }} component={Paper}>
                <Table sx={{ minWidth: 0.5 }} stickyHeader size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell width="18%">
                        <Skeleton variant="text" />
                      </TableCell>
                      <TableCell width="25%">
                        <Skeleton variant="text" />
                      </TableCell>
                      <TableCell>
                        <Skeleton variant="text" />
                      </TableCell>
                      <TableCell align="right">
                        <Skeleton variant="text" />
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell component="th" scope="row">
                        <Skeleton variant="text" />
                      </TableCell>
                      <TableCell>
                        <Skeleton variant="text" />
                      </TableCell>
                      <TableCell>
                        <Skeleton variant="text" />
                      </TableCell>
                      <TableCell align="right">
                        <Skeleton variant="text" />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Stack>
            <Stack sx={{ width: 0.5 }}>
              <Typography>
                <Skeleton variant="text" />
              </Typography>
              <Typography sx={{ fontSize: 14 }}>
                <Skeleton variant="text" />
              </Typography>
              <TableContainer component={Paper} sx={{ maxHeight: 250 }}>
                <Table stickyHeader sx={{ minWidth: 0.5 }} size="small">
                  <TableBody>
                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell colSpan={4}>
                        <Skeleton variant="text" />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Stack>
          </Stack>
        </Box>
      </Box>
    </Stack>
    <Stack sx={{ mb: 2, flex: 1 }} spacing={1}>
      <Stack
        spacing={2}
        direction="row"
        alignItems="center"
        sx={{ '&.MuiStack-root': { mt: '-14px' } }}
      >
        <Skeleton height={60} width={210} />
        <Skeleton sx={{ fontSize: 32 }} height={60} width={124} />
        <Skeleton
          variant="rounded"
          height={32}
          sx={{
            '&.MuiSkeleton-root': { ml: 'auto' },
            borderRadius: 4,
          }}
          width={60}
        />
      </Stack>
      <Skeleton variant="rounded" height="100%" width="100%" />
    </Stack>
  </Stack>
);

export default TableSkeleton;
