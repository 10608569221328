import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toRelativeUrl } from '@okta/okta-auth-js';
import { useAppContext } from '../Store';
import { clearUser, updateAuth } from '../reducer';

export type AzureSecuredRouteProps = {
  children?: React.ReactNode;
};

export const AzureSecuredRoute = ({ children }: AzureSecuredRouteProps): React.ReactElement => {
  const { dispatch, state } = useAppContext();
  const { auth } = state;
  const navigate = useNavigate();

  useEffect(() => {
    if (!auth) {
      return;
    }

    if (!auth?.isAuthenticated) {
      dispatch(clearUser());
      auth.redirectUri = toRelativeUrl(window.location.href, window.location.origin);
      dispatch(updateAuth(auth));
      navigate('/auth');
    }
  }, [auth, auth?.isAuthenticated, dispatch, navigate]);

  return <>{[auth && auth.isAuthenticated, children]}</>;
};

export default AzureSecuredRoute;
