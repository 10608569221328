import React, { ReactElement } from 'react';
import AzureSecuredRoute from '../../auth/AzureSecuredRoute';
import OktaSecuredRoute from '../../auth/OktaSecuredRoute';
import { useAppContext } from '../../Store';

const SecuredRoute = ({ children }: { children: ReactElement }) => {
  const { state } = useAppContext();

  if (state.auth.provider === 'Okta') {
    return <OktaSecuredRoute>{children}</OktaSecuredRoute>;
  }

  return <AzureSecuredRoute>{children}</AzureSecuredRoute>;
};

export default SecuredRoute;
