import { useEffect } from 'react';
import { useAppContext } from '../Store';
import { useNavigate } from 'react-router-dom';
import { clearUser } from '../reducer';

function AuthLogout() {
  const { dispatch } = useAppContext();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(clearUser());
    navigate('/');
  }, [dispatch, navigate]);

  return null;
}

export default AuthLogout;
