export const docEditDefaultValues = {
  explanation_of_impact: '',
  form_category: '',
  form_number: '',
  form_source: '',
  form_title: '',
  form_type: '',
  form_usage_category: '',
  form_usage_rule: '',
  impact_on_coverage: '',
  issuance_options: '',
  line_of_business_policy_details: '',
  manual_rate_condition: '',
  named_insured: '',
  notes: '',
  policy_number: '',
  premium_bearing: 'No',
  premium_info: '',
  requested_effective_date: '',
  state: '',
  variable_data_instructions: '',
  variable_static: '',
};

export const stateCodeArray = [
  { label: 'Alabama', key: 'AL' },
  { label: 'Alaska', key: 'AK' },
  { label: 'Arizona', key: 'AZ' },
  { label: 'Arkansas', key: 'AR' },
  { label: 'California', key: 'CA' },
  { label: 'Colorado', key: 'CO' },
  { label: 'Connecticut', key: 'CT' },
  { label: 'Delaware', key: 'DE' },
  { label: 'District of Columbia', key: 'DC' },
  { label: 'Florida', key: 'FL' },
  { label: 'Georgia', key: 'GA' },
  { label: 'Hawaii', key: 'HI' },
  { label: 'Idaho', key: 'ID' },
  { label: 'Illinois', key: 'IL' },
  { label: 'Indiana', key: 'IN' },
  { label: 'Iowa', key: 'IA' },
  { label: 'Kansas', key: 'KS' },
  { label: 'Kentucky', key: 'KY' },
  { label: 'Louisiana', key: 'LA' },
  { label: 'Maine', key: 'ME' },
  { label: 'Maryland', key: 'MD' },
  { label: 'Massachusetts', key: 'MA' },
  { label: 'Michigan', key: 'MI' },
  { label: 'Minnesota', key: 'MN' },
  { label: 'Mississippi', key: 'MS' },
  { label: 'Missouri', key: 'MO' },
  { label: 'Montana', key: 'MT' },
  { label: 'Nebraska', key: 'NE' },
  { label: 'Nevada', key: 'NV' },
  { label: 'New Hampshire', key: 'NH' },
  { label: 'New Jersey', key: 'NJ' },
  { label: 'New Mexico', key: 'NM' },
  { label: 'New York', key: 'NY' },
  { label: 'North Carolina', key: 'NC' },
  { label: 'North Dakota', key: 'ND' },
  { label: 'Ohio', key: 'OH' },
  { label: 'Oklahoma', key: 'OK' },
  { label: 'Oregon', key: 'OR' },
  { label: 'Pennsylvania', key: 'PA' },
  { label: 'Rhode Island', key: 'RI' },
  { label: 'South Carolina', key: 'SC' },
  { label: 'South Dakota', key: 'SD' },
  { label: 'Tennessee', key: 'TN' },
  { label: 'Texas', key: 'TX' },
  { label: 'Utah', key: 'UT' },
  { label: 'Vermont', key: 'VT' },
  { label: 'Virginia', key: 'VA' },
  { label: 'Washington', key: 'WA' },
  { label: 'West Virginia', key: 'WV' },
  { label: 'Wisconsin', key: 'WI' },
  { label: 'Wyoming', key: 'WY' },
];
