import React from 'react';
import { useTable, usePagination } from 'react-table';

function MetadataTable(props: any) {
  const columns = React.useMemo(
    () => [
      {
        Header: 'Form Number',
        accessor: 'form_number',
      },
      {
        Header: 'Form Title',
        accessor: 'form_title',
      },
      {
        Header: 'Form Type',
        accessor: 'form_type',
      },
      {
        Header: 'Business Unit',
        accessor: 'bu',
      },
      {
        Header: 'Sub-business Unit',
        accessor: 'opu',
      },
      {
        Header: 'Program or Product',
        accessor: 'program_or_product',
      },
      {
        Header: 'Line of Business',
        accessor: 'line_of_business',
      },
      {
        Header: 'Form Source',
        accessor: 'form_source',
      },
      {
        Header: 'Rules (M/C/O)',
        accessor: 'form_usage_category',
      },
      {
        Header: 'Carrier',
        accessor: 'carrier',
      },
      {
        Header: 'Active?',
        accessor: 'form_status',
      },
      {
        Header: 'Effective Date',
        accessor: 'effective_date',
      },
      {
        Header: 'States',
        accessor: 'states',
      },
    ],
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    { columns, data: props.data },
    usePagination
  );

  return (
    <table {...getTableProps()} style={{ borderSpacing: '0', fontSize: '12px', marginTop: '16px' }}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th
                {...column.getHeaderProps()}
                style={{
                  background: '#ddd',
                  color: 'black',
                  padding: '2px 10px',
                  fontWeight: 'bold',
                }}
              >
                {column.render('Header')}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => {
                return (
                  <td
                    {...cell.getCellProps()}
                    style={{
                      padding: '10px',
                      border: 'solid 0.5px #ccc',
                      minWidth: '150px',
                    }}
                  >
                    {cell.render('Cell')}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export default MetadataTable;
