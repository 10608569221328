import React from 'react';
import { Box, Typography } from '@mui/material';

type ErrorProps = {
  error: any;
};

export const OktaErrorHandler = (props: ErrorProps) => (
  <Box className="wrapper container">
    <Box className="content-box">
      <Typography align="center" variant="h6">
        {`${props.error}`}
      </Typography>
    </Box>
  </Box>
);

export default OktaErrorHandler;
