import * as React from 'react';
import { LoadingButton, LoadingButtonProps } from '@mui/lab';

interface ButtonProps extends LoadingButtonProps {
  variant?: 'text' | 'outlined' | 'contained';
  onClick?: () => void;
  children: any;
  sx?: any;
  disabled?: boolean;
  isLoading?: boolean;
  fullWidth?: boolean;
  component?: any;
  color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
}

const Button = ({
  variant = 'contained',
  onClick,
  children,
  sx,
  disabled = false,
  isLoading = false,
  color = 'primary',
  ...rest
}: ButtonProps) => (
  <LoadingButton
    onClick={onClick}
    variant={variant}
    color={color}
    sx={{
      textTransform: 'none',
      whiteSpace: 'nowrap',
      minWidth: 80,
      ...sx,
    }}
    disabled={disabled}
    loading={isLoading}
    {...rest}
  >
    {children}
  </LoadingButton>
);

export default Button;
