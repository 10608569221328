import React from 'react';
import { FormControl, MenuItem, InputLabel, IconButton } from '@mui/material';
import { Controller } from 'react-hook-form';
import MuiSelect, { SelectChangeEvent } from '@mui/material/Select';
import { customDisable } from '../../../theme';
import DeleteIcon from '@mui/icons-material/Delete';

type Option = {
  value: string;
  label: string;
  divider?: boolean | undefined;
  delete?: boolean | undefined;
  archived?: boolean | undefined;
};

interface ReactHookFormSelectProps {
  children: any;
  control: any;
  defaultValue: string;
  disabled?: boolean;
  errors?: any;
  id: string;
  label: string;
  labelSize?: 'normal' | 'small';
  name: string;
  onChange: any;
  required?: boolean;
  selectSize?: 'small' | 'medium';
}

export const ReactHookFormSelect = ({
  children,
  control,
  defaultValue,
  disabled,
  errors,
  id,
  label,
  labelSize,
  onChange,
  required = false,
  selectSize,
  ...props
}: ReactHookFormSelectProps) => {
  return (
    <FormControl fullWidth error={!!errors?.[id]} required={required}>
      <InputLabel id={id} size={labelSize}>
        {label}
      </InputLabel>
      <Controller
        control={control}
        defaultValue={defaultValue}
        name={id}
        rules={{ required }}
        render={({ field }) => (
          <MuiSelect
            aria-label={errors?.[id] ? 'true' : 'false'}
            labelId={id}
            label={label}
            size={selectSize}
            disabled={disabled}
            sx={{ ...customDisable }}
            {...field}
            onChange={(e: SelectChangeEvent<any>) => {
              const { value } = e.target;
              field.onChange && field.onChange(value);
              onChange && onChange();
            }}
          >
            {children}
          </MuiSelect>
        )}
      />
    </FormControl>
  );
};

interface CustomOldSelectProps {
  options: Option[];
  value: string;
  handleChange: (event: any) => void;
  label: string;
  id: string;
  labelSize?: 'normal' | 'small';
  selectSize?: 'small' | 'medium';
  disabled?: boolean;
  required?: boolean;
  error?: boolean;
  handleDelete?: (event: any, option: Option) => void;
  width?: string;
  height?: string;
  sx?: any;
}

export const OldSelect = ({
  options,
  value,
  handleChange,
  id,
  label,
  labelSize,
  selectSize,
  disabled,
  required = false,
  error = false,
  handleDelete,
  width,
  height,
  sx,
}: CustomOldSelectProps) => {
  return (
    <FormControl fullWidth required={required} error={error}>
      <InputLabel id={id} size={labelSize}>
        {label}
      </InputLabel>
      <MuiSelect
        labelId={id}
        label={label}
        value={value}
        renderValue={(value) => <span>{value}</span>}
        onChange={handleChange}
        size={selectSize}
        disabled={disabled}
        sx={{ ...sx, ...customDisable }}
        MenuProps={{
          PaperProps: {
            sx: {
              maxHeight: height,
              width: width,
              overflowX: 'auto',
            },
          },
        }}
      >
        {options.map((option) => (
          <MenuItem
            key={option.value}
            value={option.value}
            disabled={option.value === 'not-found'}
            divider={option.divider}
          >
            {option.label}
            {option.archived && ' - ARCHIVED'}
            {option.delete && (
              <IconButton
                edge="end"
                sx={{ marginLeft: 'auto' }}
                aria-label="delete"
                onClick={(e) => {
                  if (handleDelete) handleDelete(e, option);
                }}
              >
                <DeleteIcon />
              </IconButton>
            )}
          </MenuItem>
        ))}
      </MuiSelect>
    </FormControl>
  );
};

export default ReactHookFormSelect;
