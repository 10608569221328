import * as React from 'react';
import { Dialog as MuiDialog, Stack, IconButton, DialogTitle, DialogContent } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import Button from '../Button';

interface DialogProps {
  open: boolean;
  title?: string;
  id: string;
  closeDialog: () => void;
  confirmClick?: () => void;
  confirmText?: string;
  cancelClick?: () => void;
  cancelText?: string;
  children?: any;
  minWidth?: number;
  maxWidth?: number;
  disableRestoreFocus?: boolean;
}

const Dialog = ({
  open,
  title,
  id,
  closeDialog,
  confirmClick,
  confirmText,
  cancelClick,
  cancelText = 'Cancel',
  children,
  minWidth = 480,
  maxWidth,
  disableRestoreFocus = false,
}: DialogProps) => (
  <MuiDialog
    data-testid={`dialog-${id}`}
    open={open}
    onClose={closeDialog}
    PaperProps={{
      sx: {
        borderRadius: 4,
        m: 0,
        px: 3,
        position: { mobile: 'fixed', tablet: 'relative' },
        width: { mobile: 1, tablet: 'auto' },
        height: 'auto',
        maxHeight: 1,
        minWidth,
        maxWidth,
      },
    }}
    disableRestoreFocus={disableRestoreFocus}
  >
    <Stack
      direction="row"
      justifyContent="space-between"
      sx={{ width: 1, mt: { mobile: 2, tablet: 3 } }}
    >
      <DialogTitle sx={{ p: 0 }}>{title}</DialogTitle>
      <IconButton
        aria-label="close"
        data-testid={`close-button-${id}`}
        onClick={closeDialog}
        sx={{ p: 0 }}
      >
        <CloseIcon />
      </IconButton>
    </Stack>
    <DialogContent
      sx={{
        pt: { mobile: 2, tablet: 3 },
        pb: 0,
        px: 0,
      }}
    >
      {children}
      <Stack
        direction="row"
        spacing={2}
        sx={{ mt: { mobile: 3, tablet: 5 }, mb: 3, display: 'flex', justifyContent: 'flex-end' }}
      >
        <Button
          data-testid={`cancel-button-${id}`}
          variant="outlined"
          sx={{
            color: 'common.black',
            borderColor: 'common.black',
          }}
          onClick={cancelClick ?? closeDialog}
        >
          {cancelText}
        </Button>
        {confirmText && (
          <Button data-testid={`confirm-button-${id}`} onClick={confirmClick}>
            {confirmText}
          </Button>
        )}
      </Stack>
    </DialogContent>
  </MuiDialog>
);

export default Dialog;
