import React, { useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';

import Search from './Search';
import DocumentList from './DocumentList';
import { HANDLE_API_ERROR, UPDATE_SEARCH_RESULTS } from '../reducer';
import { fetchResults } from '../services';
import { useAppContext } from '../Store';

function DocSearchPage() {
  const { state, dispatch } = useAppContext();
  const [fetching, setFetching] = useState(false);
  const { isFetched, isLoading, search } = state;
  const { hits, hitsCount, filtersAreDirty } = search;
  const noHits =
    !isLoading && (search.searchQuery.length > 0 || filtersAreDirty) && hits.length === 0;

  const fetchItems = React.useCallback(async () => {
    if (fetching) {
      return;
    }
    setFetching(true);
    try {
      const { hits } = await fetchResults(search);
      dispatch({
        type: UPDATE_SEARCH_RESULTS,
        payload: hits,
      });
    } catch (error) {
      dispatch({
        type: HANDLE_API_ERROR,
      });
    } finally {
      setFetching(false);
    }
  }, [fetching, search, dispatch]);

  const loader = (
    <p className="body-text--16 text-align-center margin-bottom-32" key="loader">
      Loading...
    </p>
  );

  return (
    <>
      <Search />
      {noHits && (
        <div className="wrapper container">
          <p className="body-text--16 text-align-center">
            No results found. Please modify your search query or the filters.
          </p>
        </div>
      )}
      {!isLoading && isFetched && !noHits && (
        <InfiniteScroll
          pageStart={0}
          loadMore={fetchItems}
          hasMore={hits.length !== hitsCount}
          loader={loader}
        >
          <DocumentList docs={search.hits} />
        </InfiniteScroll>
      )}
      {!noHits && hitsCount > 0 && hits.length === hitsCount && (
        <p className="body-text--16 text-align-center margin-bottom-32">
          You have seen all results
        </p>
      )}
    </>
  );
}

export default DocSearchPage;
