import React, { useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { toRelativeUrl } from '@okta/okta-auth-js';
import { useNavigate } from 'react-router-dom';

import { useAppContext } from '../Store';
import { clearUser } from '../reducer';

interface OktaSecuredRouteProps {
  children?: React.ReactNode;
}

const OktaSecuredRoute = ({ children }: OktaSecuredRouteProps): React.ReactElement => {
  const { oktaAuth, authState } = useOktaAuth();
  const { dispatch } = useAppContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (!authState) {
      return;
    }

    if (!authState?.isAuthenticated) {
      const originalUri = toRelativeUrl(window.location.href, window.location.origin);
      oktaAuth.setOriginalUri(originalUri);
      dispatch(clearUser());
      oktaAuth.signInWithRedirect();
    }
  }, [oktaAuth, authState, authState?.isAuthenticated, dispatch, navigate]);

  return <>{[authState && authState.isAuthenticated, children]}</>;
};

export default OktaSecuredRoute;
