export const formUsageCategoryItemsOptions = [
  { value: 'Mandatory', label: 'Mandatory' },
  { value: 'Conditional', label: 'Conditional' },
  { value: 'Optional', label: 'Optional' },
];

export const impactOnCoverageItemsOptions = [
  { value: 'Broadens Coverage', label: 'Broadens Coverage' },
  { value: 'Restricts Coverage', label: 'Restricts Coverage' },
  { value: 'Clarifies Coverage', label: 'Clarifies Coverage' },
];

export const variableStaticOptions = [
  { value: 'Variable', label: 'Variable' },
  { value: 'Static', label: 'Static' },
];

export const premiumBearingOptions = [
  { value: 'Yes', label: 'Yes' },
  { value: 'No', label: 'No' },
];

export const manualRateConditionOptions = [
  { value: 'Fixed Rates', label: 'Fixed Rates' },
  { value: 'Rate Range', label: 'Rate Range' },
  { value: 'Rate Range - ref to Co.', label: 'Rate Range - ref to Co.' },
  { value: 'N/A (not premium bearing)', label: 'N/A (not premium bearing)' },
];

export const lineOfBusinessPolicyDetailsOptions = [
  { value: 'Claims-Made', label: 'Claims-Made' },
  { value: 'Occurrence', label: 'Occurrence' },
  { value: 'Both', label: 'Both' },
  { value: 'Not Applicable', label: 'Not Applicable' },
];

export const issuanceOptions = [
  { value: 'Can be issued mid-term', label: 'Can be issued mid-term' },
  {
    value: 'Can only be issued with new or renewal policies',
    label: 'Can only be issued with new or renewal policies',
  },
  { value: 'N/A', label: 'N/A' },
];

export enum NormalizedStatus {
  APPROVED = 'APPROVED',
  PENDING = 'PENDING',
  OTHER = 'OTHER',
}

export const stateStatusObject = {
  '': NormalizedStatus.APPROVED,
  Approved: NormalizedStatus.APPROVED,
  'Approved - Desk Filing': NormalizedStatus.APPROVED,
  Pending: NormalizedStatus.PENDING,
  'Deemed - File and Use': NormalizedStatus.PENDING,
  Disapproved: NormalizedStatus.OTHER,
  'No Program': NormalizedStatus.OTHER,
  'Not Filed': NormalizedStatus.OTHER,
  'Objection Received': NormalizedStatus.OTHER,
  'On Hold': NormalizedStatus.OTHER,
  Withdrawn: NormalizedStatus.OTHER,
} as const;
