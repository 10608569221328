import * as React from 'react';
import { Controller } from 'react-hook-form';
import { customDisable } from '../../../theme';
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers';
import moment from 'moment';

interface DatePickerProps {
  control: any;
  disabled?: boolean;
  errors?: any;
  id: string;
  label: string;
  required?: boolean;
  sx?: any;
  onChange?: any;
}

const DatePicker = ({
  label,
  control,
  id,
  required = false,
  errors,
  disabled = false,
  sx,
  onChange,
}: DatePickerProps) => {
  return (
    <Controller
      name={id}
      control={control}
      rules={{ required }}
      render={({ field }) => {
        return (
          <MuiDatePicker
            disabled={disabled}
            label={label}
            sx={{ ...customDisable, ...sx }}
            value={field.value ? moment(field.value) : null}
            onChange={(value) => {
              field.onChange(value?.toDate());
              onChange && onChange();
            }}
            slotProps={{
              textField: {
                error: !!errors?.[id],
              },
            }}
          />
        );
      }}
    />
  );
};

export default DatePicker;
