import React, { useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import useQueryParams from '../../services/useQueryParams';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { notify } from '../../utils/helper';
import { getFormLink } from '../../api';

const LinkLoader = () => {
  const navigate = useNavigate();
  const queryParams = useQueryParams();
  const id = queryParams.get('id');
  const source = queryParams.get('source');

  const handleRedirect = useCallback(async () => {
    if (!source || !id) {
      notify('Unable to process request.', 'error', 2500, {
        onClose: () => navigate('/search'),
      });
    } else {
      const response = await getFormLink(source, id);
      if (response.message === 'Not Found') {
        localStorage.setItem('link', JSON.stringify({ source, id }));
        notify('Request to create a new form. Loading Add Form page.', 'success', 2500, {
          onClose: () => navigate(`/forms/add`),
        });
      } else {
        notify('Request to update an existing form. Loading Edit Form page.', 'success', 2500, {
          onClose: () => navigate(`/forms/${response.data.id}`),
        });
      }
    }
  }, [id, source, navigate]);

  useEffect(() => {
    handleRedirect();
  }, [handleRedirect]);

  return (
    <FontAwesomeIcon
      icon={faSpinner}
      size="3x"
      style={{ position: 'absolute', margin: 'auto', left: 0, right: 0, top: 0, bottom: 0 }}
    />
  );
};

export default LinkLoader;
