import { createTheme } from '@mui/material';
import { Theme } from '@mui/material/styles';

declare module '@mui/styles/defaultTheme' {
  interface DefaultTheme extends Theme {}
}

declare module '@mui/material/styles' {
  interface TypographyVariants {
    timestamp: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    timestamp?: React.CSSProperties;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    timestamp: true;
  }
}

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: false; // removes the `xs` breakpoint
    sm: false;
    md: false;
    lg: false;
    xl: false;
    mobile: true; // adds the `mobile` breakpoint
    tablet: true;
    desktop: true;
  }
}

export const customDisable = {
  '& .MuiInputBase-input.Mui-disabled': {
    color: '#555',
    WebkitTextFillColor: '#555',
  },
};

const theme = createTheme({
  palette: {
    primary: {
      light: '#2661e3',
      main: '#003865',
    },
    warning: {
      main: '#FDB913',
      dark: '#d29e23',
    },
  },
  typography: {
    fontFamily: ['Proxima Nova', 'Arial', 'sans-serif'].join(','),
    timestamp: {
      fontSize: 12,
      position: 'absolute',
      zIndex: 10,
      right: '1rem',
      bottom: '-1.5rem',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.variant === 'contained' &&
            ownerState.color === 'primary' && {
              backgroundColor: '#2661e3',
              '&:hover': {
                backgroundColor: '#2252bb',
              },
            }),
          ...(ownerState.variant === 'outlined' &&
            ownerState.color === 'primary' && {
              color: '#2661e3',
              borderColor: '#2661e3',
              '&:hover': {
                color: '#2252bb',
                borderColor: '#2252bb',
                backgroundColor: 'rgba(38,97,227,.25)',
              },
            }),
          ...(ownerState.variant === 'text' &&
            ownerState.color === 'primary' && {
              color: '#2661e3',
              '&:hover': {
                color: '#2252bb',
                backgroundColor: 'rgba(38,97,227,.25)',
              },
            }),
        }),
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: () => ({
          '.MuiCollapse-wrapper': {
            paddingBottom: 16,
          },
        }),
      },
    },
  },
  breakpoints: {
    values: {
      mobile: 0,
      tablet: 640,
      desktop: 1024,
    },
  },
});

export default theme;
