import * as React from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { Checkbox, Divider, IconButton, SxProps, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface SplitButtonProps {
  options: { label: string; value: string; selected: boolean }[];
  handleChange?: (searchBy?: string) => void;
  sx?: SxProps;
}

export default function SplitButton({ options, handleChange, sx }: SplitButtonProps) {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const groupIndexes = [0, 1];
  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number
  ) => {
    let selection = getSelection(index);
    handleChange && handleChange(selection);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
  };

  const getSelection = (idx: number) => {
    options[idx].selected = !options.at(idx)?.selected;
    let groupedOptions = options.filter((option, idx) => groupIndexes.includes(idx));

    if (!groupIndexes.includes(idx)) {
      groupedOptions.forEach((option) => {
        option.selected = false;
      });
    }

    let groupSelect = groupedOptions.some((option) => option.selected === true);

    let lastItem = options.at(-1);
    if (lastItem) {
      if (!groupSelect) {
        lastItem.selected = true;
      } else {
        lastItem.selected = false;
      }
    }

    return options
      .reduce((arr: string[], cur) => {
        if (cur.selected && cur.value !== '') {
          arr.push(cur.value);
        }
        return arr;
      }, [])
      .join(',');
  };

  return (
    <React.Fragment>
      <ButtonGroup
        ref={anchorRef}
        variant="contained"
        aria-label="Split button group"
        sx={{ boxShadow: 'none', ...sx }}
      >
        <Button
          sx={{
            textTransform: 'none',
            borderRadius: '4px 0 0 4px',
            justifyContent: 'space-between',
            width: 1,
            display: 'flex',
            fontSize: 18,
          }}
          onClick={handleToggle}
        >
          Search by
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 11,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem dense>
                  <MenuItem
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      cursor: 'initial',
                      '&:hover': {
                        backgroundColor: 'initial',
                      },
                    }}
                  >
                    <Typography>Search by</Typography>
                    <IconButton onClick={handleToggle} sx={{ my: -1 }}>
                      <CloseIcon fontSize="small" />
                    </IconButton>
                  </MenuItem>
                  <Divider sx={{ '&.MuiDivider-root': { m: 0 } }} />
                  {options.map((option, index) => (
                    <MenuItem
                      key={option.value}
                      selected={option.selected}
                      onClick={(event) => handleMenuItemClick(event, index)}
                    >
                      <Checkbox sx={{ py: 0 }} checked={option.selected === true} />
                      <Typography variant="body2">{option.label}</Typography>
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  );
}
