import { SelectedItems } from './doc-search/config';
import { cloneDeep } from 'lodash';
import React from 'react';
import { notify, removeAzureAuthItemsFromLocalStorage } from './utils/helper';

import * as api from './api';

import { applyFilters } from './services';

enum DownloadStatus {
  ERROR = 'error',
  META_PENDING = 'meta-pending',
  META_SUCCESS = 'meta-success',
  PENDING = 'pending',
  SUCCESS = 'success',
}

export const defaultSearchFilters: SearchFilters = {
  form_status: ['Active'],
  is_expiring: false,
  form_type_of_business: [],
  premium_bearing: 'No',
  has_fields: false,
  impact_on_coverage: new Set() as ImpactOnCoverage,
  form_usage_category: new Set() as FormUsageCategory,
  form_type: [],
  form_source: [],
  form_category: [],
  carrier: [],
  hierarchy: [],
  line_of_business: [],
  state_filed: [],
};

const defaultFormSetsFilters: FormSetsFilters = {
  form_type: [],
  form_source: [],
  form_category: [],
  carrier: [],
  hierarchy: [],
  line_of_business: [],
  state_filed: [],
};

export const authDefault: Auth = {
  isAuthenticated: false,
  isAuthenticating: false,
  isTokenInvalid: false,
  didProcessRedirectToken: false,
  accessToken: '',
  provider: '',
  roles: [] as Array<string>,
  redirectUri: '',
  user: {
    name: '',
  },
};

const setupAuth = () => {
  try {
    const storedAuth = localStorage.getItem('paper-auth');
    if (storedAuth) {
      return JSON.parse(storedAuth);
    }
  } catch (error) {
    console.warn('Failed to parse auth object from localStorage', error);
  }

  return authDefault;
};

export const initialSearch: Search = {
  error: undefined as unknown as string,
  hits: [] as Array<any>,
  hitsCount: 0,
  areFiltersExpanded: false,
  filters: cloneDeep(defaultSearchFilters),
  filtersAreDirty: false,
  searchQuery: '',
  wasRequested: false,
  narrow: '',
};

const initialFormSets: FormSets = {
  error: undefined as unknown as string,
  hits: [] as Array<any>,
  hitsCount: 0,
  filters: cloneDeep(defaultFormSetsFilters),
  filtersAreDirty: false,
  wasRequested: false,
};

const initialViewDoc: ViewDoc = {
  hasError: false,
  id: '',
  fileUrl: '',
  canModifyFormNumber: false,
  metadata: {},
  downloadUrl: '',
  related: [],
  pdf: undefined as any,
  pdfViewer: undefined as any,
  formSource: undefined,
  status: undefined,
  user: { email: '' },
  errors: {
    products: false,
    states: [],
  },
  timestamp: '',
};

export const initialState: AppState = {
  isFetched: false,
  isLoading: false,
  auth: setupAuth(),
  download: {
    meta: [] as Array<any>,
    status: 'init' as FetchStatus,
  },
  hierarchyOptions: [] as Array<Option>,
  lobOptions: [] as Array<Option>,
  carrierOptions: [] as Array<Option>,
  search: cloneDeep(initialSearch),
  formSets: cloneDeep(initialFormSets),
  validation: {
    hasFinished: false,
    isPending: false,
    linesOfBusiness: [] as Array<string>,
    result: null as any,
  },
  viewDoc: cloneDeep(initialViewDoc),
  previousViewDocId: '',
  tempMetaData: {
    categoryType: '', // couldn't figure out what these two should be
    impactOnCoverage: '', // couldn't figure out what these two should be
    form_source: 'Proprietary',
    form_type: 'Endorsement',
    premium_bearing: '',
    raw_title: '',
    file_name_original: '',
    country: '',
    form_status: [],
    notes: '',
    file_name_pdf: '',
    form_usage_category: '',
    variable_static: '',
    bu: '',
    attachment: {},
    isMigratedFromFRRT: true,
    manual_rate_condition: '',
    raw_content: '',
    appian_id: '',
    form_type_of_business: [],
    form_title: '',
    line_of_business: '',
    opu: '',
    program_or_product: '',
    file_name_docx: '',
    form_number: '',
  },
};

// Actions
export const AUTH_REFRESH = 'AUTH_REFRESH';
export const AUTH_UPDATE = 'AUTH_UPDATE';
export const APPLY_FILTERS = 'APPLY_FILTERS';
export const CLEAR_DOC_LIST = 'CLEAR_DOC_LIST';
export const CLEAR_EXPORT_FILTERS = 'CLEAR_EXPORT_FILTERS';
export const CLEAR_FORM_SETS_HITS = 'CLEAR_FORM_SETS_HITS';
export const CLEAR_SEARCH_FILTERS = 'CLEAR_SEARCH_FILTERS';
export const CLEAR_SEARCH_HITS = 'CLEAR_SEARCH_HITS';
export const CLEAR_USER = 'CLEAR_USER';
export const HANDLE_API_ERROR = 'HANDLE_API_ERROR';
export const RESET_STATE = 'RESET_STATE';
export const SET_DOCUMENTS_META = 'SET_DOCUMENTS_META';
export const SET_DOWNLOAD_STATUS = 'SET_DOWNLOAD_STATUS';
export const SET_FORM_SETS_DATE_FILTER = 'SET_FORM_SETS_DATE_FILTER';
export const SET_FORM_SETS_SELECT_FILTER = 'SET_FORM_SETS_SELECT_FILTER';
export const SET_IS_LOADING = 'SET_IS_LOADING';
export const SET_ORG_HIERARCHY = 'SET_ORG_HIERARCHY';
export const SET_SEARCH_SELECT_FILTER = 'SET_SEARCH_SELECT_FILTER';
export const SET_USER = 'SET_USER';
export const TOGGLE_FILTERS = 'TOGGLE_FILTERS';
export const UPDATE_CHECKBOX_FILTER = 'UPDATE_CHECKBOX_FILTER';
export const UPDATE_COLLAPSE_FILTERS = 'UPDATE_COLLAPSE_FILTERS';
export const UPDATE_DOC_LIST = 'UPDATE_DOC_LIST';
export const UPDATE_DOCUMENTS = 'UPDATE_DOCUMENTS';
export const UPDATE_FORMSETS = 'UPDATE_FORMSETS';
export const UPDATE_MERGED_DOCS_FOR_PREVIEW = 'UPDATE_MERGED_DOCS_FOR_PREVIEW';
export const UPDATE_META_DATA_LIST = 'UPDATE_META_DATA_LIST';
export const UPDATE_SEARCH_QUERY = 'UPDATE_SEARCH_QUERY';
export const UPDATE_SEARCH_SCOPE = 'UPDATE_SEARCH_SCOPE';
export const UPDATE_SEARCH_RESULTS = 'UPDATE_SEARCH_RESULTS';
export const UPDATE_FORM_STATE_AND_SOURCE = 'UPDATE_FORM_STATE_AND_SOURCE';
export const RESET_VIEW_DOC = 'RESET_VIEW_DOC';
export const UPDATE_VIEW_DOC_USER = 'UPDATE_VIEW_DOC_USER';
export const UPDATE_ERRORS = 'UPDATE_ERRORS';
export const SET_PREVIOUS_VIEWDOC_ID = 'SET_PREVIOUS_VIEWDOC_ID';

// Action Creators
export const clearDocList = () => {
  const auth = {
    search: cloneDeep(initialSearch),
    isLoading: false,
    isFetched: false,
  };

  return { type: CLEAR_DOC_LIST, payload: auth };
};

export const clearExportFilters = () => {
  const formSets = {
    filters: cloneDeep(defaultFormSetsFilters),
    filtersAreDirty: false,
    wasRequested: false,
  };

  return { type: CLEAR_EXPORT_FILTERS, payload: formSets };
};

export const clearFormSetsHits = () => ({ type: CLEAR_FORM_SETS_HITS });

// const { dispatch, state } = useAppContext();
// dispatch(clearSearchFilters(state));
export const clearSearchFilters = (state: AppState) => {
  const search = { ...state.search };

  search.filters = cloneDeep(defaultSearchFilters);
  search.filters.form_type_of_business = [];
  search.searchQuery = '';
  search.hits = [];
  search.hitsCount = 0;

  if (!search.filtersAreDirty) {
    search.areFiltersExpanded = false;
  }

  return { type: CLEAR_SEARCH_FILTERS, payload: search };
};

export const clearSearchHits = () => ({ type: CLEAR_SEARCH_HITS });

export const clearUser = (): ActionCreator => {
  const auth = {
    isAuthenticated: false,
    provider: '',
    accessToken: '',
    user: { name: '' },
    isTokenInvalid: false,
    didProcessRedirectToken: false,
    redirectUri: '',
  };
  localStorage.removeItem('paper-auth');
  removeAzureAuthItemsFromLocalStorage();
  return { type: CLEAR_USER, payload: auth };
};

export const resetState = () => ({ type: RESET_STATE });

export const getDocumentsMeta = async (formNumbers: string, dispatch: React.Dispatch<any>) => {
  if (/\|/.test(formNumbers)) {
    notify(
      'Form numbers must be separated by double tilde (~~) instead of a vertical bar (|)',
      'error'
    );
    dispatch(setDownloadStatus(DownloadStatus.ERROR));
    return;
  }
  if (/[^~]~[^~]/.test(formNumbers)) {
    notify('Form number separator must be a double tilde instead of a single', 'warn');
  }
  dispatch(setDownloadStatus(DownloadStatus.META_PENDING));
  const res = await api.getDocumentsMeta(formNumbers);
  if (res === false) {
    dispatch(setDownloadStatus(DownloadStatus.ERROR));
  } else {
    dispatch(setDownloadStatus(DownloadStatus.META_SUCCESS));

    return { type: SET_DOCUMENTS_META, payload: res };
  }
};

export const setDownloadStatus = (status: string) => ({
  type: SET_DOWNLOAD_STATUS,
  payload: status,
});

export const setFormSetsDateFilter = (filterId: FormSetsFilterId, date: Date) => {
  return {
    type: SET_FORM_SETS_DATE_FILTER,
    payload: { filtersAreDirty: true, filters: { [filterId]: date } },
  };
};

export const setFormSetsSelectFilter = (filterId: FormSetsFilterId, items: SelectedItems) => {
  return {
    type: SET_FORM_SETS_SELECT_FILTER,
    payload: { [filterId]: items },
  };
};

export const updateFormSets = (formSets: FormSets) => {
  return {
    type: UPDATE_FORMSETS,
    payload: formSets,
  };
};

export const setIsLoading = (isLoading: boolean) => ({ type: SET_IS_LOADING, payload: isLoading });

export const updateOrgHierarchy = (payload: any) => ({
  type: SET_ORG_HIERARCHY,
  payload,
});

// const { dispatch, state } = useAppContext();
// dispatch(setSearchSelectFilter('hierarchy', items, dispatch, state))
export const setSearchSelectFilter = (
  filterId: SearchFilterId,
  items: SelectedItems,
  dispatch: React.Dispatch<any>,
  state: AppState
) => {
  const { search } = state;
  search.filtersAreDirty = true;
  if (search.filters[filterId]) {
    search.filters[filterId] = items || [];
  }

  return {
    type: SET_SEARCH_SELECT_FILTER,
    payload: search,
  };
};

export const setUser = (
  name: string,
  accessToken: string,
  provider: 'Azure' | 'Okta',
  roles?: Array<string>,
  oktaEmail?: string,
  oktaUsername?: string
) => {
  const email = oktaEmail !== oktaUsername ? oktaUsername : oktaEmail;

  const auth = {
    provider: provider,
    isAuthenticating: false,
    isAuthenticated: true,
    accessToken: accessToken,
    user: { name, email },
    isTokenInvalid: false,
    roles: roles || [],
    didProcessRedirectToken: false,
    redirectUri: '',
  };
  localStorage.setItem('paper-auth', JSON.stringify(auth));
  return { type: SET_USER, payload: auth };
};

export const toggleFilters = (isOpen: boolean) => ({ type: TOGGLE_FILTERS, payload: isOpen });

export const refreshAuth = () => ({ type: AUTH_REFRESH });

export const updateAuth = (auth: Auth) => {
  localStorage.setItem('paper-auth', JSON.stringify(auth));
  return { type: AUTH_UPDATE, payload: auth };
};

// const { dispatch, state } = useAppContext();
// dispatch(setCheckboxFilter(filterId, option, checked, dispatch, state))
export const setCheckboxFilter = (
  filterId: SearchFilterId,
  option: string,
  checked: boolean,
  dispatch: React.Dispatch<any>,
  state: AppState
) => {
  const search = { ...state.search };
  if (!['impact_on_coverage', 'form_usage_category'].includes(filterId)) return;
  search.filtersAreDirty = true;
  if (checked) {
    (search.filters[filterId] as Set<any>).add(option);
  } else {
    (search.filters[filterId] as Set<any>).delete(option);
  }
  applyFilters(state).then((appliedFilters) => {
    dispatch(setFilters(dispatch, appliedFilters, !state.search.areFiltersExpanded));
  });

  return { type: UPDATE_CHECKBOX_FILTER, payload: search };
};

export const setFilters = (
  dispatch: React.Dispatch<any>,
  response: { viewDoc: ViewDoc; search: Search },
  shouldCollapseFilters: Boolean = false
) => {
  dispatch(setDocList(response, !shouldCollapseFilters));

  dispatch(setIsLoading(false));

  return {};
};

export const setDocList = (
  payload: {
    viewDoc: AppState['viewDoc'];
    search: AppState['search'];
  },
  isOpen: Boolean = false
) => ({
  type: UPDATE_DOC_LIST,
  payload: { ...payload, isLoading: false, isFetched: true, areFiltersExpanded: isOpen },
});

export const setSearchQuery = (query: string) => ({ type: UPDATE_SEARCH_QUERY, payload: query });

export const setSearchScope = (narrow?: string) => ({ type: UPDATE_SEARCH_SCOPE, payload: narrow });

export const validateDocuments = async (query: Array<ValidationQuery>, state: AppState) => {
  const validation = { ...state.validation };

  if (validation.isPending || validation.hasFinished) {
    return;
  }

  validation.isPending = true;
  validation.linesOfBusiness = query.map((q) => q.l);
  validation.result = await api.getValidation(query);
  validation.isPending = false;
  validation.hasFinished = true;

  return { type: UPDATE_DOCUMENTS, payload: validation };
};

export const setViewDoc = (viewDoc: ViewDoc) => ({
  type: UPDATE_MERGED_DOCS_FOR_PREVIEW,
  payload: viewDoc,
});

export const getMergedDocumentsFile = async (formNumbers: string) => {
  if (/\|/.test(formNumbers)) {
    notify(
      'Form numbers must be separated by double tilde (~~) instead of a vertical bar (|)',
      'error'
    );
    return;
  }

  await api.getMergedDocumentsFile(formNumbers);
};

// goodish I believe
export const getMergedDocumentsZip = async (formNumbers: string, dispatch: React.Dispatch<any>) => {
  if (/\|/.test(formNumbers)) {
    notify(
      'Form numbers must be separated by double tilde (~~) instead of a vertical bar (|)',
      'error'
    );
    return;
  }

  dispatch(setDownloadStatus(DownloadStatus.PENDING));

  const res = await api.getMergedDocumentsZip(formNumbers);

  if (res === true) {
    return setDownloadStatus(DownloadStatus.SUCCESS);
  }
  if (res === false) {
    return setDownloadStatus(DownloadStatus.ERROR);
  }
};

// const { state, dispatch } = useAppContext();
// dispatch(setSearchFilter('form_status', newState ? 'Active' : 'Inactive', dispatch, state))
export const setSearchFilter = async (
  filterId: SearchFilterId,
  value: string | boolean | Date | undefined | string[],
  dispatch: React.Dispatch<any>,
  state: AppState
) => {
  // [TODO] figure out the TS type instead of any
  const search: any = { filters: state.search.filters || {} };

  if (filterId === 'form_status') {
    search.filters['form_status'] = value as unknown as FormStatus;
  } else if (filterId === 'premium_bearing') {
    search.filters['premium_bearing'] = value as YesNo;
  } else {
    search.filters[filterId] = value as any;
  }

  const appliedFilters = await applyFilters(state);
  dispatch(setFilters(dispatch, appliedFilters, !state.search.areFiltersExpanded));

  return { type: SET_SEARCH_SELECT_FILTER, payload: search };
};

// Reducer
export const reducer = (state: AppState, action: ActionCreator) => {
  switch (action.type) {
    case AUTH_REFRESH:
      return {
        ...state,
        auth: { ...state.auth, isTokenInvalid: true, didProcessRedirectToken: true },
      };
    case AUTH_UPDATE:
      return { ...state, auth: { ...state.auth, ...action.payload } };
    case APPLY_FILTERS:
      return { ...state, search: { ...state.search, areFiltersExpanded: action.payload } };
    case CLEAR_DOC_LIST:
      return { ...state, auth: action.payload };
    case CLEAR_EXPORT_FILTERS:
      return {
        ...state,
        formSets: { ...state.formSets, ...action.payload, hits: [], hitsCount: 0 },
      };
    case CLEAR_FORM_SETS_HITS:
      return { ...state, formSets: { ...state.formSets, hits: [], hitsCount: 0 } };
    case CLEAR_SEARCH_FILTERS:
      return { ...state, search: action.payload };
    case CLEAR_SEARCH_HITS:
      return { ...state, search: { ...state.search, hits: [], hitsCount: 0 } };
    case CLEAR_USER:
      return { ...state, auth: action.payload };
    case RESET_STATE:
      return { ...initialState };
    case SET_DOCUMENTS_META:
      return { ...state, download: { ...state.download, meta: action.payload } };
    case SET_DOWNLOAD_STATUS:
      return { ...state, download: { ...state.download, status: action.payload } };
    case SET_FORM_SETS_DATE_FILTER:
      return {
        ...state,
        formSets: {
          ...state.formSets,
          filtersAreDirty: action.payload.filtersAreDirty,
          filters: {
            ...state.formSets.filters,
            ...action.payload.filters,
          },
        },
      };
    case SET_FORM_SETS_SELECT_FILTER:
      return {
        ...state,
        formSets: {
          ...state.formSets,
          filtersAreDirty: true,
          filters: { ...state.formSets.filters, ...action.payload },
        },
      };
    case SET_IS_LOADING:
      return { ...state, isLoading: action.payload };
    case SET_ORG_HIERARCHY:
      return { ...state, ...action.payload };
    case SET_SEARCH_SELECT_FILTER:
      return { ...state, search: { ...state.search, ...action.payload } };
    case SET_USER:
      return { ...state, auth: action.payload };
    case UPDATE_FORMSETS:
      return { ...state, formSets: { ...state.formSets, ...action.payload } };
    case TOGGLE_FILTERS:
      return {
        ...state,
        search: { ...state.search, areFiltersExpanded: action.payload },
      };
    case UPDATE_CHECKBOX_FILTER:
      return { ...state, search: action.payload };
    case UPDATE_COLLAPSE_FILTERS:
      return { ...state, areFiltersExpanded: false };
    case UPDATE_DOC_LIST:
      return {
        ...state,
        search: {
          ...state.search,
          ...action.payload.search,
          areFiltersExpanded: action.payload.areFiltersExpanded,
        },
        viewDoc: {
          ...state.viewDoc,
          ...action.payload.viewDoc,
        },
        isFetched: action.payload.isFetched,
      };
    case UPDATE_DOCUMENTS:
      return { ...state, validation: action.payload };
    case UPDATE_MERGED_DOCS_FOR_PREVIEW:
      return { ...state, viewDoc: action.payload };
    case UPDATE_META_DATA_LIST: {
      const { isLoading, formSets, isFetched } = action.payload;

      return { ...state, isLoading, isFetched, formSets: { ...state.formSets, ...formSets } };
    }
    case UPDATE_SEARCH_QUERY:
      return { ...state, search: { ...state.search, searchQuery: action.payload } };
    case UPDATE_SEARCH_SCOPE:
      return { ...state, search: { ...state.search, narrow: action.payload } };
    case UPDATE_SEARCH_RESULTS:
      return { ...state, search: { ...state.search, hits: action.payload } };
    case UPDATE_FORM_STATE_AND_SOURCE:
      return {
        ...state,
        viewDoc: {
          ...state.viewDoc,
          ...{ status: action.payload.status, formSource: action.payload.formSource },
        },
      };
    case RESET_VIEW_DOC:
      return { ...state, viewDoc: initialViewDoc };
    case UPDATE_VIEW_DOC_USER:
      return { ...state, viewDoc: { ...state.viewDoc, user: action.payload } };
    case UPDATE_ERRORS:
      return { ...state, viewDoc: { ...state.viewDoc, errors: action.payload } };
    case SET_PREVIOUS_VIEWDOC_ID:
      return { ...state, previousViewDocId: action.payload };
    default:
      return state;
  }
};
