import { useQuery } from '@tanstack/react-query';
import { LAMBDA_FORMS } from './constants';
import authenticatedApi from './authenticatedApi';
import { handleUnauthorizedError } from '../api';
import { notify } from '../utils/helper';

export const getDocEditFile = async (id?: string): Promise<any> => {
  if (!id) {
    return Promise.reject(new Error('Missing ID'));
  }
  try {
    const res = await authenticatedApi.get<DocEditDocumentResponse>(`${LAMBDA_FORMS}/forms/${id}`);
    return res;
  } catch (error: any) {
    let errorJson = null;
    let message = 'Failed to load document.';

    if (error.name === 'HTTPError') {
      errorJson = await error.response.json();
      const { message: errorMessage } = errorJson;
      message = errorMessage;
    }

    if (!handleUnauthorizedError(error)) {
      notify(message, 'error');
    }

    throw errorJson;
  }
};

export const useDocEditFileQuery = (id?: string) =>
  useQuery({
    queryKey: ['docEditFile', id], 
    queryFn: () => getDocEditFile(id),
    staleTime: 1000 * 60 * 3,
    retry: false,
  });
