import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

type TooltipifyPropsType = {
  children?: React.ReactNode;
  text?: string | React.ReactElement;
  displayFullContent?: boolean;
  className?: string;
};

type TooltipifyContentPropsType = {
  children: React.ReactNode;
  parentSize: number;
  setDisplay: (activate: boolean) => void;
};

const TooltipifyContent = ({ children, parentSize, setDisplay }: TooltipifyContentPropsType) => {
  const ref = React.useRef<any>(null);
  React.useEffect(() => {
    if (ref.current && parentSize > 0) {
      if (parentSize < ref.current.offsetWidth) {
        setDisplay(true);
      } else {
        setDisplay(false);
      }
    }
  }, [parentSize, setDisplay]);
  return <span ref={ref}>{children}</span>;
};

export const Tooltipify = ({
  children,
  text,
  className,
  displayFullContent = false,
}: TooltipifyPropsType) => {
  const ref = React.useRef<any>(null);
  const [parentSize, setParentSize] = React.useState<number>(0);
  const [displayTooltip, setDisplayTooltip] = React.useState<boolean>(displayFullContent);

  React.useEffect(() => {
    function handleResize() {
      setParentSize(ref.current.offsetWidth);
    }

    if (ref.current) {
      handleResize();
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const setDisplay = React.useCallback(
    (activate: boolean) => {
      setDisplayTooltip(activate);
    },
    [setDisplayTooltip]
  );

  return (
    <Tooltip
      title={displayTooltip ? <Typography sx={{ fontFamily: 18 }}>{text}</Typography> : ''}
      className={className}
    >
      <Typography
        sx={{
          fontSize: 14,
          textWrap: 'nowrap',
          ...(displayTooltip
            ? { overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'pre' }
            : {}),
        }}
        ref={ref}
      >
        <TooltipifyContent parentSize={parentSize} setDisplay={setDisplay}>
          {children ?? text}
        </TooltipifyContent>
      </Typography>
    </Tooltip>
  );
};
