export const LAMBDA_FORMS =
  process.env.REACT_APP_FORMS_API_URL ||
  'https://cknuac8882.execute-api.us-east-1.amazonaws.com/prod';

export const LAMBDA_ORG =
  process.env.REACT_APP_ORG_API_URL ||
  'https://2herg1ecsd.execute-api.us-east-1.amazonaws.com/prod';

export const LAMBDA_FILE =
  process.env.REACT_APP_FILE_API_URL ||
  'https://ng38qi4ybf.execute-api.us-east-1.amazonaws.com/prod';

export const LAMBDA_SEARCH =
  process.env.REACT_APP_API_URL || 'https://3t90oa8uf4.execute-api.us-east-1.amazonaws.com/prod';
