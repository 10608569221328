import { LAMBDA_FORMS } from './constants';
import authenticatedApi from './authenticatedApi';
import { notify } from '../utils/helper';
import { handleUnauthorizedError } from '../api';

export const updateDocEditMetaDataVariableMode = async ({
  id,
  metadata,
  options,
}: UpdateDocEditMetaDataVariableModeI & unknown): Promise<DocEditDocumentPutResponse | null> => {
  return updateDocEditMetaData(id, metadata, options);
};

export const updateDocEditMetaData: UpdateDocEditMetaDataI = async (id, metadata, options) => {
  try {
    let uri = `${LAMBDA_FORMS}/forms/${id}`;
    if (options) {
      const urlParts = [];
      if (options.status) {
        urlParts.push(`status=${options.status}`);
      }
      if (options.canModifyFormNumber) {
        urlParts.push(`canModifyFormNumber=${options.canModifyFormNumber}`);
      }
      if (options.link) {
        urlParts.push(`source=${options.link.source}&id=${options.link.id}`);
      }
      if (urlParts.length > 0) {
        uri += `?${urlParts.join('&')}`;
      }
    }

    const res = (await authenticatedApi.put(uri, {
      body: JSON.stringify(metadata),
    })) as DocEditDocumentPutResponse;

    return res;
  } catch (error: any) {
    let errorJson = null;
    let message = 'Failed to update document. Please try again later.';

    if (error.name === 'HTTPError') {
      errorJson = await error.response.json();
      const { message: errorMessage } = errorJson;
      message = errorMessage;
    }

    if (!handleUnauthorizedError(error)) {
      notify(message, 'error');
    }

    throw errorJson;
  }
};
