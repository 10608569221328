import Box from '@mui/material/Box';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { useDebounce, useDebouncedCallback } from 'use-debounce';
import SearchFilters from './SearchFilters';
import './Search.css';
import { useAppContext } from '../Store';
import {
  SET_PREVIOUS_VIEWDOC_ID,
  setDocList,
  setIsLoading,
  setSearchQuery,
  setSearchScope,
} from '../reducer';
import { exportSearchResults, fetchDocList } from '../services';
import Button from '../components/styledComponents/Button';
import { notify } from '../utils/helper';
import SplitButton from './SplitButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';

export const filterByOptions = [
  { label: 'Form Number', value: 'formNumber', selected: false },
  { label: 'Title', value: 'formTitle', selected: false },
  { label: 'All', value: '', selected: true },
];

const Search = () => {
  const { state, dispatch } = useAppContext();
  const inputRef = useRef<HTMLInputElement>(null);
  const [value, setValue] = useState(inputRef.current?.value || state.search.searchQuery);
  const [exportIsLoading, setExportIsLoading] = useState(false);
  const [narrow, setNarrow] = useState<string | undefined>(state.search.narrow);
  const [searchValue] = useDebounce(value, 1500);

  const getSearchResults = useCallback(async () => {
    dispatch(setIsLoading(true));
    dispatch(setSearchQuery(searchValue));
    dispatch({ type: SET_PREVIOUS_VIEWDOC_ID, payload: '' });
    const docList = await fetchDocList(searchValue, state);

    if (docList) {
      dispatch(setDocList(docList));
    } else {
      console.error('docList undefined and could not update state');
    }
    dispatch(setIsLoading(false));
  }, [searchValue, state, dispatch]);

  const debouncedGetSearchResults = useDebouncedCallback(getSearchResults, 750);

  useEffect(() => {
    if (searchValue !== state.search.searchQuery) {
      getSearchResults();
    }
  }, [searchValue, state.search.searchQuery, getSearchResults]);

  useEffect(() => {
    if (state.search.narrow !== narrow) {
      setNarrow(state.search.narrow);
      if (searchValue.length > 0) {
        debouncedGetSearchResults();
      }
    }
  }, [state.search.narrow, narrow, searchValue.length, debouncedGetSearchResults]);

  const search = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value;
    setValue(query);
    if (inputRef.current) {
      inputRef.current.value = query;
    }
  };

  const handleChange = async (narrow?: string) => {
    dispatch(setSearchScope(narrow));
  };

  const { isLoading } = state;
  const { hitsCount } = state.search;
  const showHits = !isLoading && hitsCount > 0;

  const displaySearchBy = () => {
    const labelMap: { [key: string]: string } = {
      formNumber: 'Form Number',
      formTitle: 'Form Title',
    };
    const narrowParts = narrow?.split(',');
    const displayLabel = narrowParts?.reduce((arr, cur) => {
      if (labelMap[cur]) {
        arr = arr === 'All' ? labelMap[cur] : `${arr} and ${labelMap[cur]}`;
      }
      return arr;
    }, 'All');

    return displayLabel;
  };

  return (
    <Box className="section-full" sx={{ flexDirection: 'column' }}>
      <Stack sx={{ width: 1, px: 4, pb: 3 }}>
        <Stack className="container wrapper">
          <h2 className="page-title">
            Let's take a look
            <Tooltip
              title={
                <React.Fragment>
                  <Typography>How to narrow search results?</Typography>
                  <Typography sx={{ fontSize: 14 }}>
                    Search by form number, form title, or general search; Use Filters to narrow
                    results even further.
                  </Typography>
                </React.Fragment>
              }
              sx={{ ml: 1 }}
            >
              <InfoOutlinedIcon />
            </Tooltip>
          </h2>
          <Stack
            direction="row"
            alignSelf="center"
            sx={{
              width: 0.75,
              pb: 4,
            }}
          >
            <SplitButton
              sx={{ borderRadius: '4px 0 0 4px' }}
              options={filterByOptions}
              handleChange={handleChange}
            />
            <TextField
              type="search"
              sx={{
                flex: 1,
                '& .MuiOutlinedInput-notchedOutline': {
                  borderLeft: 'none',
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                },
              }}
              ref={inputRef}
              value={value}
              onChange={search}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" disablePointerEvents>
                    <SearchIcon color="primary" />
                    <Typography sx={{ fontSize: 16, lineHeight: '23px', ml: 1 }}>
                      {displaySearchBy()}:
                    </Typography>
                  </InputAdornment>
                ),
              }}
            />
          </Stack>
          <SearchFilters />
        </Stack>
        {(showHits || isLoading) && (
          <div style={{ textAlign: 'center', fontWeight: 'bold' }}>
            {isLoading && <FontAwesomeIcon icon={faSpinner} />}
            {showHits && [hitsCount === 10000 ? '10000+' : hitsCount, ' hits']}
          </div>
        )}
        {(showHits || isLoading) && (
          <Stack alignItems="flex-end" sx={{ mt: -4 }}>
            <Button
              isLoading={exportIsLoading || isLoading}
              onClick={async () => {
                if (hitsCount < 1000) {
                  setExportIsLoading(true);
                  await exportSearchResults(searchValue, state, hitsCount, narrow);
                  setExportIsLoading(false);
                } else {
                  notify(
                    'If you would like to download your results, please refine your results with filter',
                    'info'
                  );
                }
              }}
            >
              Download Results CSV
            </Button>
          </Stack>
        )}
      </Stack>
    </Box>
  );
};

export default Search;
