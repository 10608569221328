import * as api from './api';
import { notify, transformFilters } from './utils/helper';
import { initialSearch } from './reducer';
import * as jsonexport from 'jsonexport/dist';
import { saveAs } from 'file-saver';
import { stateCodeArray } from './constants/defaultValues';

const getMetadata = async (id: string) => {
  return api.getDocumentMetadata(id);
};

export const loadDocFile = async (id: string, state: AppState) => {
  if (state.isLoading || id === state.viewDoc.id) {
    return;
  }

  const viewDoc = { ...state.viewDoc };

  try {
    const result = await api.fetchDocument(id);
    if (result) {
      viewDoc.hasError = false;
      viewDoc.fileUrl = result;
    } else {
      viewDoc.hasError = true;
    }
    viewDoc.id = id;
    const response = await getMetadata(id);
    if (response && response.data) {
      const metadata = response.data;
      viewDoc.metadata = metadata.doc;
      viewDoc.downloadUrl = metadata.downloadUrl;
      viewDoc.related = metadata.related;
    }
  } catch (error) {
    console.error(error);
  }

  return viewDoc;
};

export const loadDocEditFile = async (id: string, state: AppState) => {
  if (state.isLoading) return;

  const viewDoc: any = { ...state.viewDoc };

  try {
    const result = await api.fetchDocEditDocument(id);

    if (result) {
      viewDoc.hasError = false;
      viewDoc.file = result;
      viewDoc.metadata = result?.data?.metadata;
      if (result.data) {
        viewDoc.formSource = result.data.formSource;
        viewDoc.status = result.data.status;
        viewDoc.user.email = result.data?.user?.email || '';
      }
    } else {
      viewDoc.hasError = true;
    }
    viewDoc.id = id;
  } catch (error) {
    console.error(error);
  }

  return viewDoc;
};

export const fetchResults = async (search: typeof initialSearch) => {
  const offset = search.hits.length;
  const narrow = search.narrow;
  const searchFilters = transformFilters(search.filters);
  const newResults = await api.fetchSearchResults(
    search.searchQuery,
    searchFilters,
    offset,
    narrow
  );
  search.hits = search.hits.concat(newResults.hits);
  return { hits: search.hits };
};

export const fetchDocList = async (query: string, state: AppState) => {
  const viewDoc = { ...state.viewDoc };
  const search = { ...state.search };

  viewDoc.fileUrl = '';
  viewDoc.id = '';
  viewDoc.hasError = false;
  viewDoc.downloadUrl = '';
  viewDoc.metadata = {};

  try {
    const searchFilters = transformFilters(search.filters);
    const narrow = search.narrow;

    const newResults = await api.fetchSearchResults(query, searchFilters, 0, narrow);

    search.searchQuery = newResults.query;
    search.hits = newResults.hits;
    search.hitsCount = newResults.count;
    search.error = undefined as unknown as string;
    search.searchQuery = query;
  } catch (error) {
    if (error instanceof Error) {
      search.error = error.message;
    }
    search.hits = [];
    search.hitsCount = 0;
    return { search, viewDoc };
  }

  return { viewDoc, search };
};

export const applyFilters = async (state: AppState) => {
  const response = await fetchDocList(state.search.searchQuery, state);

  return response;
};

export const exportMetadata = async (formSets: FormSets) => {
  try {
    const csv = await jsonexport(formSets.hits, {
      headers: [
        'form_number',
        'form_title',
        'form_type',
        'division_or_segment',
        'business_unit',
        'operating_unit',
        'program_or_product',
        'line_of_business',
        'carrier',
        'form_source',
        'form_usage_category',
        'state_filed',
        'state_effective_date',
        'state_expired_date',
      ],
      rename: [
        'Form Number',
        'Form Title',
        'Form Type',
        'Division or Segment',
        'Business Unit',
        'Operating Unit',
        'Program or Product',
        'Line of Business',
        'Carrier',
        'Form Source',
        'Rules (M/C/O)',
        'States',
        'Effective Date',
        'Expiry Date',
      ],
    });
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8,' });
    await saveAs(blob, 'paper-export.csv');
    return Promise.resolve('Paper export to CSV complete');
  } catch (err) {
    console.error(err);
    return Promise.reject('Paper export to CSV failed');
  }
};

export const fetchMetadataList = async (formSetsOriginal: FormSets) => {
  const formSets = { ...formSetsOriginal };

  if (!formSets.filtersAreDirty && formSets.hitsCount > 0) {
    return formSets;
  }
  formSets.wasRequested = true;

  const filters = transformFilters(formSets.filters);

  try {
    const newResults = await api.fetchMetadata(filters);
    if (newResults.error) {
      throw new Error(newResults.error);
    }
    formSets.hits = newResults.hits;
    formSets.hitsCount = newResults.count;
    formSets.filtersAreDirty = false;
    formSets.error = undefined as unknown as string;
  } catch (error) {
    if (error instanceof Error) {
      formSets.error = error.message;
    }
  }

  return formSets;
};

export const exportSearchResults = async (
  query: string,
  state: AppState,
  hitsCount: number,
  narrow?: string
) => {
  const search = { ...state.search };

  try {
    const searchFilters = transformFilters(search.filters);
    const newResults = await api.fetchSearchResults(query, searchFilters, 0, narrow, hitsCount);

    search.hits = newResults.hits;

    if (search.hits) {
      const jsonReadableHits = search.hits.map((hit) => {
        const newHit = { ...hit._source };
        stateCodeArray.map((stateName) => {
          if (!newHit.state_filings) {
            return (newHit.AL = 'NO STATE FILINGS');
          }
          return (newHit[stateName.key] = newHit.state_filings.find(
            (stateFiled: typeof newHit.state_filings[0]) => stateFiled.state_filed === stateName.key
          )
            ? 'X'
            : '');
        });
        delete newHit.state_filings;
        return newHit;
      });

      const csv = await jsonexport(jsonReadableHits, {
        headers: [
          'form_number',
          'form_title',
          'form_type',
          'program_or_product',
          'line_of_business',
          'form_source',
          'bu',
          'country',
          'file_name_docx',
          'file_name_original',
          'file_name_pdf',
          'form_status',
          'form_type_of_business',
          'notes',
          'opu',
          'attachment.content',
          'attachment.title',
        ],
        rename: [
          'Form Number',
          'Form Title',
          'Form Type',
          'Program or Product',
          'Line of Business',
          'Form Source',
          'Business Unit',
          'Country',
          'File Name docx',
          'File Name Original',
          'File Name pdf',
          'Form Status',
          'Form Type of Business',
          'Notes',
          'OPU',
          'Attachment Content',
          'Attachment Title',
        ],
      });
      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8,' });
      await saveAs(blob, 'paperSearchResultsExport.csv');
    } else {
      notify('Sorry, something went wrong, please try again later.', 'error');
    }
  } catch (err) {
    console.error(err);
    notify('Sorry, something went wrong, please try again later.', 'error');
  }
};
