import * as React from 'react';
import {
  Radio as MuiRadio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  FormHelperText,
} from '@mui/material';
import { Controller } from 'react-hook-form';

type Option = {
  value: string;
  label: string;
};

interface RadioProps {
  options: Option[];
  title: string;
  control: any;
  id: string;
  required?: boolean;
  errors?: any;
  disabled?: boolean;
}

const Radio = ({ options, title, control, id, required = false, errors, disabled }: RadioProps) => (
  <FormControl fullWidth error={!!errors?.[id]}>
    <FormLabel id="radioStyled">{title}</FormLabel>
    <Controller
      name={id}
      control={control}
      rules={{ required }}
      render={({ field }) => {
        Object.assign(field, { value: field.value || '' });
        return (
          <RadioGroup
            id={id}
            aria-labelledby="radioStyled"
            aria-invalid={errors?.[id] ? 'true' : 'false'}
            {...field}
          >
            {options.map((option) => (
              <FormControlLabel
                key={option.value}
                value={option.value}
                control={<MuiRadio />}
                label={option.label}
                disabled={disabled}
              />
            ))}
          </RadioGroup>
        );
      }}
    />
    {errors?.[id]?.type === 'required' && <FormHelperText>{title} is required</FormHelperText>}
  </FormControl>
);

export default Radio;
