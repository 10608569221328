import React from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { useLocation } from 'react-router-dom';
import * as queryString from 'query-string';

import WebViewer from '../doc-view/WebViewer';

import '../doc-view/DocViewPage.css';
import { useAppContext } from '../Store';
import { setUser } from '../reducer';
import { getMergedDocumentsForPreview } from '../api';

function DocApiPreview(props: any) {
  const { authState } = useOktaAuth();
  const { state, dispatch } = useAppContext();
  const location = useLocation();

  if (!state.auth.accessToken) {
    if (authState.isAuthenticated && authState.accessToken) {
      dispatch(setUser('', authState.accessToken, 'Okta'));
    } else {
      console.error('Unauthenticated request');
    }
  }
  const query = queryString.parse(location.search);

  if (state.viewDoc.id !== query.forms) {
    dispatch(getMergedDocumentsForPreview(query.forms as string));
  }

  if (state.viewDoc.hasError) {
    return (
      <section className="main">
        <div className="doc-header">
          <h1>The requested file is missing or faulty</h1>
        </div>
      </section>
    );
  }

  if (!state.viewDoc.fileUrl) {
    return (
      <section className="main">
        <div className="doc-header">
          <h1>The preview is being prepared...</h1>
        </div>
      </section>
    );
  }
  return (
    <section className="main">
      <div className="doc-preview">
        <WebViewer source={state.viewDoc.fileUrl} allowAnnotations={true} />
      </div>
    </section>
  );
}

export default DocApiPreview;
